import { useNavigate } from 'react-router-dom';
import './index.scss'
import React from 'react';
import { Col, Layout, Image, Row } from 'antd';
import elevator09 from '../../assets/images/type_elevator09.jpg';

import home01 from '../../assets/images/home01.webp';
import home02 from '../../assets/images/home02.webp';
import home03 from '../../assets/images/home03.webp';
import observation41 from '../../assets/images/home04.webp';
import home05 from '../../assets/images/home05.webp';
import home06 from '../../assets/images/home06.webp';
import observation61 from '../../assets/images/observation61.png';
import observation71 from '../../assets/images/observation71.png';
import passenger61 from '../../assets/images/passenger61.webp';
const { Header, Content, Footer } = Layout;
class Observation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Home Elevator',
      header_title: 'Home elevator',
      header_content: 'Fuji home elevators / villa elevators / villa lifts are designed to provide a luxurious but affordable means of vertical transportation for multi-level homes. It adopts Fuji advanced innovative drive systems to ensure reliable, quiet and smooth operation. A wide range of car designs allow you to customize your elevator to match your interior decoration.',

      top1_titel: '1. Optional Car Design:',
      top1_tabs: [
        {
          img: home01,
          texts: [
            { text0: 'Model: FJ-TJJ-009', text1: 'Hairline stainless steel' },
            { text0: 'Model: FJ-TJJ-010', text1: 'Mirror stainless steel' },
            { text0: 'Model: FJ-TJJ-011', text1: 'Titanium, etched, mirror st/st' },
            { text0: 'Model: FJ-TJJ-001', text1: 'Painted stainless steel' },

          ],
        },
        {
          img: home02,
          texts: [
            { text0: 'Model: FJ-TJJ-004', text1: 'Wooden imitation surface' },
            { text0: 'Model: FJ-TJJ-007', text1: 'Etched, mirror, hairline St/St' },
            { text0: 'Model: FJ-TJJ-006', text1: 'Titanium, mirror stainless steel' },
            { text0: 'Model: FJ-TJJ-008', text1: 'Transparent glass' },
          ],
        }
      ],



      top2_titel: '2. Optional car ceiling series',
      top2_tabs: {
        img: home03, texts: ['FJ-D23', 'FJ-D24', 'FJ-D25'],
      },

      top4_titel: '4. Optional Car Flooring',
      top4_tabs: {
        img: passenger61, texts: ['FJ-JD01', 'FJ-JD02', 'FJ-JD05', 'FJ-JD06', 'FJ-JD07', 'FJ-JD09', 'FJ-JD19', 'FJ-JD18', 'FJ-JD08'],
      },


      top3_titel: '3. Optional car door / landing door series',
      top3_tabs: {
        img: home06,
        texts: [
          { text0: 'FJ-TM01', text1: 'Hairline ST / ST' },
          { text0: 'FJ-TM02', text1: 'Painted plate' },
          { text0: 'FJ-TM13', text1: 'Mirror / etching' },
          { text0: 'FJ-TM12', text1: 'Glass / hairline' },
          { text0: 'FJ-TM04', text1: 'Titanium / etching / mirror' },
          { text0: 'FJ-TM20', text1: 'Titanium / etching / mirror' },
        ]
      },

      top5_titel: '5. Optional COP and LOP designs：',
      top5_tabs: {
        imgs: [
          { img: observation41, texts: ['FJ-JC01 FJ-JC04 FJ-JC-05', 'FJ-JC-06', 'FJ-JC07', 'FJ-JC08', 'FJ-JC09'] },
        ],
      },

      top6_titel: '6. Optional Hall buttons( LOP) designs：',
      top6_tabs: {
        img: home05, texts: ['FJ-JZ01', 'FJ-Z16', 'FJ-JZ-05', 'FJ-JZ-06', 'FJ-JZ08', 'FJ-JZ09'],
      },

      top7_titel: '7. Standard Functions',

      top8_titel: '8. Optional Functions',
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { code } = this.state;
    return (
      <Layout style={{ background: '#FFFFFF' }}>

        <Content className="site-layout" style={{ padding: '1rem' }}>
          <div>
            <h2>{this.state.header_title}</h2>
            <p>{this.state.header_content}</p>
            <div className='ele-img'><img src={elevator09} /></div>
          </div>

          <div>
            <h4>{this.state.top1_titel}</h4>
            {
              this.state.top1_tabs.map((item, indexs) => {
                return (<div className='to-top' key={indexs}>
                  <img src={item.img} />
                  <div className='img-text'>
                    {
                      item.texts.map((item, indexs) => {
                        return (<div key={indexs}><p style={{ textAlign: 'center' }}>{item.text0}</p><p style={{ textAlign: 'center' }}>{item.text1}</p></div>
                        )
                      })
                    }
                  </div>

                </div>)
              })
            }
          </div>



          <div>
            <h4>{this.state.top2_titel}</h4>
            <div>
              <img src={this.state.top2_tabs.img} />
              <div className='img-text'>
                {
                  this.state.top2_tabs.texts.map((item, indexs) => {
                    return (
                      <p key={indexs}>{item}</p>)
                  })
                }
              </div>
            </div>
          </div>

          <div>
            <h4>{this.state.top3_titel}</h4>
            <div>
              <img src={this.state.top3_tabs.img} />
              <div className='img-text'>
                {
                  this.state.top3_tabs.texts.map((item, indexs) => {
                    return (<div key={indexs} style={{ width: '16%' }}><p style={{ textAlign: 'center' }}>{item.text0}</p><p style={{ textAlign: 'center' }}>{item.text1}</p></div>
                    )
                  })
                }
              </div>
            </div>
          </div>

          <div>
            <h4>{this.state.top4_titel}</h4>
            <div>
              <img src={this.state.top4_tabs.img} />
              <div className='img-text'>
                {
                  this.state.top4_tabs.texts.map((item, indexs) => {
                    return (
                      <p key={indexs}>{item}</p>)
                  })
                }
              </div>
            </div>
          </div>

          <div>
            <h4>{this.state.top5_titel}</h4>
            {
              this.state.top5_tabs.imgs.map((val, element) => {
                return (<div key={element} style={{ paddingBottom: '1rem' }}>
                  <img src={val.img} />
                  <div className='img-text'>
                    {val.texts.map((e, num) => {
                      return (<div style={{ width: '20%' }}><p style={{ textAlign: 'center' }} key={num}>{e}</p></div>)
                    })}

                  </div>
                </div>)
              })
            }
          </div>

          <div>
            <h4>{this.state.top6_titel}</h4>
            <div>
              <img src={this.state.top6_tabs.img} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {
                  this.state.top6_tabs.texts.map((item, indexs) => {
                    return (
                      <p key={indexs}>{item}</p>)
                  })
                }
              </div>
            </div>
          </div>

          <div>
            <h4>{this.state.top7_titel}</h4>
            <div><img src={observation61} /></div>
          </div>
          <div>
            <h4>{this.state.top8_titel}</h4>
            <div><img src={observation71} /></div>
          </div>
        </Content>
      </Layout>
    )
  }
}


export default Observation;
