import { useNavigate, Outlet } from 'react-router-dom';

import React from 'react';
import { Image, Layout, Col, Row } from 'antd';
import Headertop from '../../components/Headertop';
import Nextroute from '../../components/Nextroute';
import Foot from '../../components/Foot';
import about from '../../assets/images/about.webp';
import './index.scss';

import elevator01 from '../../assets/images/type_elevator01.jpg';
import elevator02 from '../../assets/images/type_elevator02.jpg';
import elevator03 from '../../assets/images/type_elevator03.jpg';
import elevator04 from '../../assets/images/type_elevator04.jpg';
import elevator05 from '../../assets/images/type_elevator05.jpg';
import elevator06 from '../../assets/images/type_elevator06.jpg';
import elevator07 from '../../assets/images/type_elevator07.jpg';
import elevator08 from '../../assets/images/type_elevator08.jpg';
import elevator09 from '../../assets/images/type_elevator09.jpg';
const slides = [
  { img: elevator05, text: 'Passenger Elevator', to: '/products/passenger_elevator' },
  { img: elevator06, text: 'Observation Elevator', to: '/products/observation_elevator' },
  { img: elevator07, text: 'Freight Elevator', to: '/products/freight_elevator' },
  { img: elevator08, text: 'Hospial Elevator', to: '/products/hospital_elevator' },
  { img: elevator09, text: 'Home Elevator', to: '/products/home_elevator' },
  { img: elevator01, text: 'Car Elevator', to: '/products/car_elevator' },
  { img: elevator02, text: 'Hydraulic Elevators', to: '/products/hydraulic_elevator' },
  { img: elevator03, text: 'Escalator & Moving Walk', to: '/products/escalator_movingWalk' },
  { img: elevator04, text: 'Dumbwaiter', to: '/products/dumbwaiter' },


]
const { Header, Content, Footer } = Layout;

export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};
class Products extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'About Us',
      routeitems: [],
      keyRout: '',
      img_page: ''
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  getRouteitems = (e) => {
    this.setState({
      routeitems: e
    })
  }
  getKeyrout = (e) => {
    this.setState({
      keyRout: e
    })
  }
  getChildren = (e) => {
    this.setState({
      img_page: e
    })
  }
  enterLink = (e) => {
    this.setState({
      img_page: ''
    }, () => {
      this.props.navigate(e)
    })

  }
  render() {
    const { img_page, keyRout } = this.state;
    console.log(this.state, 'products');
    return (
      <Layout style={{ backgroundColor: '#FFFFFF' }}>
        <Headertop name={this.state.title} getRouteitems={this.getRouteitems} getKeyrout={this.getKeyrout} />
        {/* <Content className="site-layout" style={{background:'#FFFFFF'}}> */}
        <Row justify="center" style={{ width: '100%' }}>
          <Col span={20}>
            <img style={{ width: '100%' }} src={about} />
          </Col>
        </Row>
        <Row justify="center" gutter={{ xs: 8, sm: 16, md: 24 }} style={{ width: '100%' }}>
          <Col span={5} style={{ flex: 'none' }}>
            <Nextroute routeitems={this.state.routeitems} keyRout={this.state.keyRout} getChildren={this.getChildren} />
          </Col>
          <Col span={15}>
            <div style={{ border: '1px solid rgba(0, 0, 0, 0.15)', background: '#FFFFFF' }}>
              <div style={{ padding: '1rem' }}>
                {
                  img_page == 'Products' ?
                    <Row justify='center' gutter={[{ xs: 4, sm: 8, md: 16, lg: 24 }, { xs: 4, sm: 8, md: 16, lg: 24 }]}>
                      {
                        slides.map((item, indexs) => {
                          return (<Col span={8} key={indexs} >
                            <div onClick={() => this.enterLink(item.to)} className='nav-img'>
                              <div className='imgs'>
                                <img style={{ width: '100%' }} src={item.img}></img>
                              </div>
                              <div className='nav-text' >{item.text}</div>
                            </div>

                          </Col>)
                        })
                      }

                    </Row>
                    : null
                }
                <Outlet />
              </div>
            </div>
          </Col>
        </Row>
        <Foot />
      </Layout>
    )
  }
}


export default withNavigation(Products);
