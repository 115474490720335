import { useNavigate,Outlet } from 'react-router-dom';

import React from 'react';
import { Layout, Image, theme } from 'antd';
import Headertop from '../../components/Headertop';
import Nextroute from '../../components/Nextroute';
// import home01 from '../../assets/images/home01.png';
const { Header, Content, Footer } = Layout;

class New extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title:'News',
      routeitems: [],
      keyRout:'',
      img_page: ''
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  getRouteitems=(e)=>{
    this.setState({
      routeitems: e
    })
  }
  getKeyrout=(e)=>{
    this.setState({
      keyRout: e
    })
  }
  getChildren=(e)=>{
    this.setState({
      img_page: e
    })
  }
  render() {
    const { code } = this.state;
console.log(this.state,'00000');
    return (
      <Layout>
        <Headertop name={this.state.title} getRouteitems={this.getRouteitems} getKeyrout={this.getKeyrout} />
        <Content className="site-layout" style={{}}>
          <Nextroute routeitems={this.state.routeitems} keyRout={this.state.keyRout} getChildren={this.getChildren} />
          <Outlet/>
        </Content>
      </Layout>
    )
  }
}


export default New;
