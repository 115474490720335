import { useNavigate } from 'react-router-dom';
import './index.scss'
import React from 'react';
import { Col, Layout, Image, Row } from 'antd';
import freight01 from '../../assets/images/freight01.webp';
import freight02 from '../../assets/images/freight02.webp';
import freight03 from '../../assets/images/freight03.webp';
import freight04 from '../../assets/images/freight04.webp';
import freight05 from '../../assets/images/freight05.webp';
import freight06 from '../../assets/images/freight06.webp';
const { Header, Content, Footer } = Layout;
class Freight extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Freight Elevator',
      header_title: 'Freight elevator',
      header_content: 'FUJI freight elevator / goods lift uses advanced FUJI original package PLC control technology and FUJI VVVF technology, so the performance and quality of elevator are improved greatly; and they can meet the user’s needs in different purpose and environment such as factory, warehouse, shopping mall, hotel, airport and station.',
      
      top1_titel: 'Main Features:',
      top1_content: "Fuji Elevator’s “human-oriented” concept combines with unique design and lovely ornament. FUJI Elevator studies the relations between vision, audition, touch sensation and human, and reaches harmony unification of use function and esthetic feeling in a relatively-closed space. All these are expressions of human natures, and thus meet the needs that human pursue for a comfortable and unique living environment. All these reflect that Fuji Elevator dedicates itself to looking for ways to show its respect for human natures.",
      top1_tabs: [
        {
          title: '(1). Round-shape design',
          content: 'Full range of types availble.  customized solution could be provided to meet different requirements.'
        },
        {
          title: '(2) Strong and durable, large load capacity:',
          content: 'It adopts very strong and durable materials. Full range of load capacity for option. When exceeding 3000 KG  load, it adopts 6-guide rail to operate.'
        },
        {
          title: '(3). Excellent torque, big door opening:',
          content: 'Constant torque character ensures the operating speed, comfortability and leveling accuracy are not affected, no matter whatever load.'
        },
        {
          title: '(4) Energy saving, environment friendly, economic:',
          content: 'The application of VVVF technology makes the elevator running consumption and power consumption be reduced substantially, saving nearly 20%, compared with traditional AC double speed lifting technology.'
        },
      ],
      top2_titel: 'Design Styles',
      top3_titel: 'Key components:',
      top4_titel: 'Construction Parameters',
     
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { code } = this.state;
    console.log(this.state);
    return (
      <Layout style={{ background: '#FFFFFF' }}>

        <Content className="site-layout" style={{ padding: '1rem' }}>
          <div>
            <h2>{this.state.header_title}</h2>
            <p>{this.state.header_content}</p>
            <div style={{width:'70%',padding: '1rem 0 0 0'}} ><img src={freight01} /></div>
            

          </div>

          <div>
            <h3>{this.state.top1_titel}</h3>
            {
              this.state.top1_tabs.map((item, indexs) => {
                return (<div key={indexs}>
                  <h4 className='nh4'>{item.title}</h4>
                  <p>{item.content}</p>

                </div>)
              })
            }
            <p>{this.state.top1_points}</p>
          </div>

          <div>
            <h3>{this.state.top2_titel}</h3>
            {/* <p>{this.state.top2_content}</p> */}
            <hr/>
            <div><img src={freight02}/></div>
            <hr/>
            <div><img src={freight03}/></div>
          </div>
          <div>
            <h3>{this.state.top3_titel}</h3>
            <div>
              <img src={freight04} />
              </div>
          </div>

          <div>
            <h3>{this.state.top4_titel}</h3>
            <div><img src={freight05} /></div>
              <div><img src={freight06} /></div>
          </div>

         
        </Content>
      </Layout>
    )
  }
}


export default Freight;
