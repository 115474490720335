import './index.scss';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Tabs, Layout, Image } from 'antd';
import routes from '../../routes/index';
import wechatImg from '../../assets/images/wechat_img.png';
import retract from '../../assets/images/home_retract_icon.png';
import dropdown from '../../assets/images/home_dropdown_icon.png';
import link_01 from '../../assets/images/link_01.webp';
import link_02 from '../../assets/images/link_02.webp';
import link_03 from '../../assets/images/link_03.webp';
import link_04 from '../../assets/images/link_04.webp';
import link_05 from '../../assets/images/link_05.webp';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
const { Footer } = Layout;


export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};


class Foot extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayWechat: 'none',
      displayOne: 'none',
      displayTwo: 'none',
      retractOne: retract,
      retractTwo: retract,
    }

  }

  componentWillReceiveProps = (nextProps) => {
  }

  changeWechat = () => {
    this.setState({
      displayWechat: 'block'
    })
  }
  onMouseOut = () => {
    this.setState({
      displayWechat: 'none',
      displayTwo: 'none',
      displayOne: 'none',
      retractTwo: retract,
      retractOne: retract,
    })
  }
  itemOne = () => {
    this.setState({
      displayOne: 'block',
      retractOne: dropdown
    })
  }
  itemTwo = () => {
    this.setState({
      displayTwo: 'block',
      retractTwo: dropdown
    })
  }
  render() {
    return (
      <Footer style={{ backgroundColor: '#FFFFFF', padding: '0' }}>

        <div className="w1200 footer-law">
          <div className="link-all">
            <ul style={{ display: 'flex', width: '100px', textAlign: 'center' }}>
              <li><a href='https://www.facebook.com/Fuji-Elevator-Asia-CoLtd-401708830442376/'><Image width={25} src={link_01} preview={false} /></a></li>
              <li><a href='https://www.facebook.com/Fuji-Elevator-Asia-CoLtd-401708830442376/'><Image width={25} src={link_02} preview={false} /></a></li>
              <li><a href='https://www.facebook.com/Fuji-Elevator-Asia-CoLtd-401708830442376/'><Image width={25} src={link_03} preview={false} /></a></li>
              <li><a href='https://www.facebook.com/Fuji-Elevator-Asia-CoLtd-401708830442376/'><Image width={25} src={link_04} preview={false} /></a></li>
              <li><a href='https://www.facebook.com/Fuji-Elevator-Asia-CoLtd-401708830442376/'><Image width={25} src={link_05} preview={false} /></a></li>
            </ul>
          </div>
          <p style={{marginBottom:'20px'}}>FUJI ELEVATOR (ASIA)CO.,LTD.</p>
          <p>Email:export@cnfuji.com - Tel:+86 189 5888 8553  /  +86 577 8550 9750 - WhatsApp:+86 189 5888 8553 - Wechat:767785741</p>
          <p>Address：Fuji Industrial Park, No. 519, Binhai 15 Road, Economic And Technological Development Zone,Wenzhou, Zhejiang, China</p>
          <p>Office：21F, Building C, Ouhai Headquarters Economical Park, No. 81, Zhonghui Road, Ouhai District, Wenzhou City, Zhejiang Province, China</p>
          <p><a>Privacy policy</a> <a>Terms and conditions</a></p>
        </div>
      </Footer>
    )
  }
}


export default withNavigation(Foot);
