import { useNavigate } from 'react-router-dom';
import './index.scss';
import React from 'react';
import { Carousel, Layout, Button, Image } from 'antd';
import Slider from '@alifd/next/lib/slider';
import '@alifd/next/lib/slider/style';
import Headertop from '../../components/Headertop';
import Foot from '../../components/Foot';
import swiper01 from '../../assets/images/swiper01.jpg';
import swiper02 from '../../assets/images/swiper02.jpg';
import swiper03 from '../../assets/images/swiper03.jpg';
import swiper04 from '../../assets/images/swiper04.jpg';
import swiper05 from '../../assets/images/swiper05.jpg';
import swiper06 from '../../assets/images/swiper06.jpg';
import revolve01 from '../../assets/images/revolve01.webp';
import revolve02 from '../../assets/images/revolve02.webp';
import revolve03 from '../../assets/images/revolve03.webp';
import revolve04 from '../../assets/images/revolve04.webp';
import revolve05 from '../../assets/images/revolve05.webp';
import elevator01 from '../../assets/images/type_elevator01.jpg';
import elevator02 from '../../assets/images/type_elevator02.jpg';
import elevator03 from '../../assets/images/type_elevator03.jpg';
import elevator04 from '../../assets/images/type_elevator04.jpg';
import elevator05 from '../../assets/images/type_elevator05.jpg';
import elevator06 from '../../assets/images/type_elevator06.jpg';
import elevator07 from '../../assets/images/type_elevator07.jpg';
import elevator08 from '../../assets/images/type_elevator08.jpg';
import elevator09 from '../../assets/images/type_elevator09.jpg';
const { Header, Content, Footer } = Layout;
const revolve_imgs =
  [
    { img: revolve01, text: 'Quality Certification' },
    { img: revolve02, text: 'On-time Delivery' },
    { img: revolve03, text: 'Quality Company' },
    { img: revolve04, text: 'After-sales Warranty' },
    { img: revolve05, text: 'Overseas Service' }
  ];
const slides = [
  { img: elevator01, text: 'Car Elevator' },
  { img: elevator02, text: 'Hydraulic Elevators' },
  { img: elevator03, text: 'Escalator & Moving Walk' },
  { img: elevator04, text: 'Dumbwaiter' },
  { img: elevator05, text: 'Passenger Elevator' },
  { img: elevator06, text: 'Observation Elevator' },
  { img: elevator07, text: 'Freight Elevator' },
  { img: elevator08, text: 'Hospial Elevator' },
  { img: elevator09, text: 'Home Elevator' }
].map((item,indexs) => (
  <div style={{ width: "25%" }} key={indexs}>
    <Image width="200" height="200" src={item.img} preview={false} />
    <h4 className="h4">{item.text}</h4>
  </div>
));
const swiperImages = [swiper01, swiper02, swiper03, swiper04, swiper05, swiper06];
class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Home',
      mid_title: 'About Us',
      card_content: "Office Buildings of Sudan's Ministry of Foreign Affairs Office Buildings of Chana Ministry of Foreign Affairs  Flamingo Tower, Kenya  Hospital Catarino Rivas, Honduras  Nile College, Sudan San Jose Church, Guatemala AISER HOTEL, Almaty, Kazakstan Ind...",
      mid_title_info: 'Fuji Elevator was established in 1946 in Japan and is one of the world leading elevator brands. As a foreign-owned company, Fuji Elevator (Asia) Co.,Ltd invested and built factories in China to serve global market directly since 1996. Fuji elevator product range includes passenger elevator, freight elevator (cargo lift), observation elevator (panoramic lift), car elevator, home elevator, hydraulic elevator, escalator, moving walkway, stereo parking garages etc.',
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }

  getRouteitems=()=>{

  }
  getKeyrout=()=>{

  }

  render() {
    let clientWidth = document.body.clientWidth + 'px'
    return (
      <Layout>
        <Headertop getRouteitems={this.getRouteitems} getKeyrout={this.getKeyrout} />
        <Content className="site-layout" style={{ backgroundColor: '#FFFFFF' }}>

          <div className='swiper'>
            <Carousel
              effect="scrollx"
              dots={true}
              dotPosition='bottom'
              autoplay={true}
            >
              {
                swiperImages.map((item, indexs) => {
                  return <Image width={clientWidth} height='auto' key={indexs} src={item} preview={false} />
                })
              }
            </Carousel>
          </div>
          <div className='section1'>
            <div className='w1200'>
              <a href="https://www.xnskg.cn/investor/stock">
                <p style={{textAlign:'center',fontSize:'28px'}}>{this.state.mid_title}</p>
              </a>
              <div className='about_content'><p style={{ lineHeight: '2' }}><span style={{ fontFamily: 'arial, helvetica, sans-serif', fontSize: '16px', color: '#000000' }}>{this.state.mid_title_info}</span></p></div>
            </div>
          </div>

          <div className='section2'>
            <div className='w1200'>

              <div className="mix-duoyuan-box">
                {

                  revolve_imgs.map((element, indexs) => {
                    return (
                      <div className='duoyuan-one' key={indexs}>
                        <Image src={element.img} width="86rpx" preview={false} />
                        <p style={{textAlign:'center'}}>{element.text}</p>
                      </div>
                    )
                  })
                }

              </div>

              <div className='type-swiper'>
                <p>Products</p>
                <Slider
                  slidesToShow={5}
                  arrowPosition="inner"
                  // autoplaySpeed={0.5}
                  autoplay={true}
                  lazyLoad>
                  {slides}
                </Slider>
              </div>
              <div className='bottom-card'>
                <p>Project</p>
                <div className='card'>
                  <div className='top-image'>
                    <div className='mid-image'>
                    <div className="shadow"></div></div>
                    
                  </div>
                  <div className='card-content'>
                    <h4 className='h4'>Parts of classic Projects</h4>
                    <p>{this.state.card_content}</p>
                    <div className='nav-content'>
                      <Button size='large'>More</Button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </Content>
        <Foot />
      </Layout>
    )
  }
}
export default Home;
