import { useNavigate } from 'react-router-dom';

import React from 'react';
import { Carousel, Layout, Image, theme } from 'antd';
import chart01 from '../../assets/images/chart01.webp';
const { Header, Content, Footer } = Layout;
class Team extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title:'Organization Chart',
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { code } = this.state;

    return (
      <Layout>
        
        <Content className="site-layout" style={{backgroundColor:'#FFFFFF'}}>
            <h3 style={{textAlign:'center'}}>Fuji Elevator (Asia) Co.,Ltd.</h3>
          <h3 style={{textAlign:'center',paddingLeft:'3rem'}}><span>I I</span><br/><span>I I</span></h3>
          <img src={chart01}/>
        
        </Content>
      </Layout>
    )
  }
}


export default Team;
