
import React from 'react';
import Index from '../pages/Index/index.js';
import Home from '../pages/Home';
import New from '../pages/New';
import About from '../pages/About';
import Newsone from '../pages/Newsone';
import Join from '../pages/Join';
import Products from '../pages/Products';
import Investor from '../pages/Investor';
import Profile from '../pages/Profile';
import History from '../pages/History';
import Culture from '../pages/Culture';
import Team from '../pages/Team';
import Qualification from '../pages/Qualification';
import Responsibility from '../pages/Responsibility';
import Connect from '../pages/Connect';
import Passenger from '../pages/Passenger';
import Observation from '../pages/Observation';
import Comprehensive from '../pages/Comprehensive';
import Other from '../pages/Other';
import Information from '../pages/Information';
import Announcements from '../pages/Announcements';
import Governance from '../pages/Governance';
import Stock from '../pages/Stock';
import Exchange from '../pages/Exchange';
import Email from '../pages/Email';
import People from '../pages/People';
import Recruitment from '../pages/Recruitment';
import Hold from '../pages/Hold';
import Fuji from '../pages/Fuji';

let routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "about",
    element: <About />,
    children: [
      {
        path: "",
        element: <Profile />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "organization_chart",
        element: <Team />,
      },
      {
        path: "history",
        element: <History />,
      },
      {
        path: "qualification_certificate",
        element: <Culture />,
      },
      // {
      //   path: "qualification",
      //   element: <Qualification />,
      // },
      // {
      //   path: "responsibility",
      //   element: <Responsibility />,
      // },
      // {
      //   path: "connect",
      //   element: <Connect />,
      // }
    ]
  },
  // {
  //   path: "news",
  //   element: <New />,
  //   children: [
  //     {
  //       path: "newsone",
  //       element: <Newsone />,
  //     },
  //     {
  //       path: "indexs",
  //       element: <Index />,
  //     },
  //   ]
  // },
  {
    path: "products",
    element: <Products />,
    children: [
      {
        path: "",
        element: <Passenger />,
      },
      {
        path: "passenger_elevator",
        element: <Passenger />,
      },
      {
        path: "observation_elevator",
        element: <Observation />,
      },
      {
        path: "hospital_elevator",
        element: <Comprehensive />,
      },
      {
        path: "home_elevator",
        element: <Other />,
      },
        {
        path: "car_elevator",
        element: <Information />,
      },
      {
        path: "hydraulic_elevator",
        element: <Announcements />,
      },
      {
        path: "escalator_movingWalk",
        element: <Governance />,
      },
      {
        path: "dumbwaiter",
        element: <Stock />,
      },
      {
        path: "stereo_parking_garages",
        element: <Exchange />,
      },
      {
        path: "freight_elevator",
        element: <Email />
      }
      

    ]
  },
  {
    path: "project",
    element: <Investor />,
    children: [
      
      {
        path: "stock",
        element: <Stock />,
      },
      {
        path: "exchange",
        element: <Exchange />,
      },
      {
        path: "email",
        element: <Email />
      }

    ]
  },
  {
    path: "contact",
    element: <Join />,
    // children: [
    //   {
    //     path: "people",
    //     element: <People />,
    //   },
    //   {
    //     path: "recruitment",
    //     element: <Recruitment />,
    //   },
    // ]
  },
  {
    path: "hold",
    element: <Hold />,
  },
  ,
  {
    path: "fuji",
    element: <Fuji />,
  },
];
 
export default routes;