import { useNavigate } from 'react-router-dom';

import React from 'react';
import './index.scss';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Timeline, Layout } from 'antd';
const { Header, Content, Footer } = Layout;

class History extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '发展历程',
      companyInfo:
        [
          {
            year: '1946/01',
            info: ['Fuji Elevator (Japan) Corporation was established. '],
            img: '',
            color: '',
            dot: true
          },
          {
            year: 2009,
            info: ['与阿里云战略合作，推进工业信息化产业'],
            img: '',
            color: '',
            dot: true
          },
          {
            year: 2009,
            info: ['投资雷亚电子,开发电源物联网设备产业。'],
            img: '',
            color: '',
            dot: true
          },
          {
            year: 2009,
            info: ['2009年9月,公司创立。', '聚焦工业制造业和贸易业务'],
            img: '',
            color: '',
            dot: true
          },
          {
            year: 2009,
            info: ['与阿里云战略合作，推进工业信息化产业'],
            img: '',
            color: '',
            dot: true
          }


        ]
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { code } = this.state;

    return (
      <Layout>
        
        <Content className="site-layout" style={{ backgroundColor: '#FFFFFF' }}>
          <div className="page-content">
            <div className="center-content">
              <p className="cn">Fuji Elevator & Fuji Holdings in China</p>
            </div>
            <Timeline
              mode="alternate"
              // pending={true}
              reverse={true}
              items={[
                {
                  children: (<div ><span>2018/12</span><div><p>Guizhou Fuji Holdings Elevator Co., Ltd was prepared to construct in Anshun city, Guizhou Province as a new manufacturing base, supplying Fuji elevators to west China market. It occupies an area of 200 acres ( about 133,333 square meters) with total investment value 400 million RMB ( =67 million USD).</p></div></div>),
                },{
                  children: (<div ><span>2018/12</span><div><p>Guizhou Fuji Holdings Elevator Co., Ltd was prepared to construct in Anshun city, Guizhou Province as a new manufacturing base, supplying Fuji elevators to west China market. It occupies an area of 200 acres ( about 133,333 square meters) with total investment value 400 million RMB ( =67 million USD).</p></div></div>),
                },{
                  children: (<div ><span>2018/06</span><div><p>Guizhou Fuji Holdings Elevator Co., Ltd was prepared to construct in Anshun city, Guizhou Province as a new manufacturing base, supplying Fuji elevators to west China market. It occupies an area of 200 acres ( about 133,333 square meters) with total investment value 400 million RMB ( =67 million USD).</p></div></div>),
                },{
                  children: (<div ><span>2016/12</span><div><p>Guizhou Fuji Holdings Elevator Co., Ltd was prepared to construct in Anshun city, Guizhou Province as a new manufacturing base, supplying Fuji elevators to west China market. It occupies an area of 200 acres ( about 133,333 square meters) with total investment value 400 million RMB ( =67 million USD).</p></div></div>),
                },{
                  children: (<div ><span>2016/05</span><div><p>New Fuji trademark /logo was registered in Japan, the registration number is 5853125.</p></div></div>)
                },{
                  children: (<div ><span>2014/11</span><div><p>Guizhou Fuji Holdings Elevator Co., Ltd was prepared to construct in Anshun city, Guizhou Province as a new manufacturing base, supplying Fuji elevators to west China market. It occupies an area of 200 acres ( about 133,333 square meters) with total investment value 400 million RMB ( =67 million USD).</p></div></div>),
                },{
                  children: (<div ><span>2014/05</span><div><p>Anhui Fuji Holdings Elevator Co., Ltd was established in Bengbu city, Anhui Province as a new manufacturing base, supplying Fuji elevators to north China markets and part of overseas market. It occupies an area of 300 acres (about 200,000 square meters) with total investment value 1 billion RMB ( =167 million USD).</p></div></div>),
                },{
                  children: (<div ><span>2012 05</span><div><p>Fuji Elevator was ranked in the list of China foreign-aid project supplied products.  Main Projects are: Office buildings of the Ministry of Foreign Affairs of Ghana, Office buildings of the Ministry of Foreign Affairs of Sudan etc.</p></div></div>),
                },{
                  children: (<div ><span>2010/04</span><div><p>Zhejiang Fuji Holdings Elevator Co., Ltd was established with 12.5 Million USD Registered Capital at Fuji Industrial Park in Wenzhou as a Fuji Elevator manufacturing base for overseas market, occupying an area of 120 acres ( about 80,000 square meters). Later, the registered capital added to 32 Million USD.</p></div></div>),
                },{
                  children: (<div ><span>2008/02</span><div><p>Fuji Holdings signed an investment agreement with China government, and started construction of building Fuji Industrial Park, occupying an area of 300 acres (= 200,000 square meters) at National Wenzhou Economic & Technological Development Zone in Wenzhou City, Zhejiang province, China. Total investment value is 98 million USD.</p></div></div>),
                },{
                  children: (<div ><span>2007/11</span><div><p>Fuji (China) Holdings Co., Ltd (hereinafter referred as “Fuji Holdings”), formerly known as Fuji (Asia) Industrial Investment Co., Ltd., was jointly founded by Fuji Elevator (Asia) Co., Ltd., Fuji Electric (Asia) System Co., Ltd., Fuji Electric (China) Technology Service Co.,Ltd. and Hong Kong Industrial Investment Funds. Operated by Fuji Holdings, Fuji Elevator (Asia) Co.,Ltd expanded investment in China to serve global market.</p></div></div>),
                },{
                  children: (<div ><span>2004/08</span><div><p>	
                  Fuji Elevator (Asia) Co.,Ltd built an elevator & escalator assembly base in Suzhou, and accessories manufacturing bases in Shanghai and Ningbo through shareholding. </p></div></div>),
                },{
                  children: (<div ><span>2002/03</span><div><p>Fuji Elevator (Asia) Co.,Ltd invested to build Jiangxi Fuji Elevator Co., Ltd as first manufacturing base in mainland China with 18 Million RMB Registered Capital, occupying an area of 50 acres (about 33,335 square meters).</p></div></div>),
                  color: 'green',
                },
                {
                  children: (<div ><span>1998/05</span><div><p>Fuji Elevator (Asia) Co.,Ltd was set up to manufacture, sell, install and maintain Fuji Elevators to serve global market directly.</p></div></div>),
                },
                {
                  color: 'red',
                  children: (<div ><span>1996/01</span><div><p>Fuji Elevator started to enter China mainland market.                  </p></div></div>),
                },
                {
                  dot: (
                    <ClockCircleOutlined
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  ),
                  children: (<div ><span>1946/01</span><div><p>Fuji Elevator (Japan) Corporation was established. </p></div></div>),
                },
                
                
              ]}
            />
          </div>
        </Content>
        <Footer style={{ backgroundColor: '#FFFFFF', padding: '24px 0' }}>
        </Footer>

      </Layout>
    )
  }
}


export default History;
