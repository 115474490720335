import { useNavigate } from 'react-router-dom';
import './index.scss'
import React from 'react';
import { Col, Layout, Image, Row } from 'antd';
import hospital01 from '../../assets/images/hospital01.webp';
import hospital02 from '../../assets/images/hospital02.webp';
import hospital03 from '../../assets/images/hospital03.webp';
const { Header, Content, Footer } = Layout;
class Hospital extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Hospital Elevator',
      header_title: 'Hospital elevator',
      header_content: 'FUJI hopital elevator / medical elevator emphasizes that “the medical institution should be designed to be a comfortable magical place which not only help the patient recover health but also is livable”, and pays close attention to patients’ affection health and influence on the patient from physical environment; compatible and comfortable environment design can get over the patient’s helplessness and encourage the the patient to be self-assured; reasonable space plan and layout can reduce the non-convenience of the patient, his(her) relatives and friends and medical personnel, making the hospital and its advanced equipment provide more services for the patient. Elevator environment design is very important for health recovery, as you select the FUJI medical elevator, you can say to the patient: “You actually live in a hotel”.',
      
      top1_titel: 'Construction Parameters',
      top1_content: "Fuji Elevator’s “human-oriented” concept combines with unique design and lovely ornament. FUJI Elevator studies the relations between vision, audition, touch sensation and human, and reaches harmony unification of use function and esthetic feeling in a relatively-closed space. All these are expressions of human natures, and thus meet the needs that human pursue for a comfortable and unique living environment. All these reflect that Fuji Elevator dedicates itself to looking for ways to show its respect for human natures.",
      top1_tabs: [
        {
          title: '(1). Round-shape design',
          content: 'Full range of types availble.  customized solution could be provided to meet different requirements.'
        },
        {
          title: '(2) Strong and durable, large load capacity:',
          content: 'It adopts very strong and durable materials. Full range of load capacity for option. When exceeding 3000 KG  load, it adopts 6-guide rail to operate.'
        },
        {
          title: '(3). Excellent torque, big door opening:',
          content: 'Constant torque character ensures the operating speed, comfortability and leveling accuracy are not affected, no matter whatever load.'
        },
        {
          title: '(4) Energy saving, environment friendly, economic:',
          content: 'The application of VVVF technology makes the elevator running consumption and power consumption be reduced substantially, saving nearly 20%, compared with traditional AC double speed lifting technology.'
        },
      ],
      top2_titel: 'Design Styles',
      top3_titel: 'Key components:',
      top4_titel: 'Construction Parameters',
     
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { code } = this.state;
    console.log(this.state);
    return (
      <Layout style={{ background: '#FFFFFF' }}>

        <Content className="site-layout" style={{ padding: '1rem' }}>
          <div>
            <h2>{this.state.header_title}</h2>
            <div><img src={hospital01} /></div>
            <p className='to-top'>{this.state.header_content}</p>
          </div>
          <hr style={{marginTop:'3rem'}} />
          <div><img src={hospital02}/></div>
          <hr style={{margin:'1rem 0'}} />
          <div><img src={hospital03}/></div>

         
        </Content>
      </Layout>
    )
  }
}


export default Hospital;
