import { useNavigate } from 'react-router-dom';

import React from 'react';
import { Carousel, Layout, Row, Col } from 'antd';
import Foot from '../../components/Foot';
import './index.scss';
import about_us01 from '../../assets/images/about_us01.webp';
const { Header, Content, Footer } = Layout;
class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Company Profile',
      contents:[
        'Fuji Elevator was established in 1946 in Japan and is one of the top 10 world leading elevator brands. As a foreign-owned company, Fuji Elevator (Asia) Co.,Ltd invested and built factories in China to serve global market directly since 1996. Fuji elevator product range includes passenger elevator, freight elevator (cargo lift), observation elevator (panoramic lift), car elevator, home elevator, hydraulic elevator, escalator, moving walkway, stereo parking garages etc.',
        'Fuji Elevator (Asia) Co.,Ltd has invested and built elevator factories in Zhejiang, Jiangxi, Jiangsu, Anhui, Guizhou of China through the operation of Fuji (China) Holdings Co.,Ltd. Fuji Elevator operation center in China is located at Fuji Industrial Park, Wenzhou city, Zhejiang Province.',
        'Presently, Fuji Holdings has five wholly owned elevator manufacturing subsidiaries: Zhejiang Fuji Holdings Elevator Co., Ltd., Jiangxi Fuji Elevator Co.,Ltd, Anhui Fuji Holdings Elevator Co., Ltd, Guizhou Fuji Holdings Elevator Co., Ltd and Fuji Holdings Elevator Equipment Co., Ltd. ',
        'After years of development, Fuji Holdings becomes a diversified multinational group, holding or shareholding more than 20 companies. Fuji Holdings operates a wide range of business including manufacturing elevators, CCTV systems (security cameras and video recorders), frequency conversion motor, photovoltaic power inverter, intelligent robot; investments in industry, real estate, finance, agriculture etc.; overseas trading in metal and non-metal resources.'
      ]
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { contents } = this.state;

    return (
      <Layout style={{ backgroundColor: '#FFFFFF' }}>
        
        <Content style={{ width: '100%', margin: '0 auto' }}>
          <div>
          {contents.map((item,indexs)=>{
            return (<p style={{fontSize:'16px',padding:'15px 0',textAlign:'left',lineHeight:'2rem'}} key={indexs}>{item}</p>)
          })}
          </div>
          <div>
              <img src={about_us01}/>
          </div>
          
        </Content>
      </Layout>
    )
  }
}


export default Profile;
