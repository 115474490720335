import { useNavigate } from 'react-router-dom';
import './index.scss'
import React from 'react';
import { Col, Layout, Image, Row } from 'antd';
import elevator01 from '../../assets/images/type_elevator01.jpg';
import elevator02 from '../../assets/images/type_elevator02.jpg';
import elevator03 from '../../assets/images/type_elevator03.jpg';
import elevator04 from '../../assets/images/type_elevator04.jpg';
import elevator05 from '../../assets/images/type_elevator05.jpg';
import elevator06 from '../../assets/images/type_elevator06.jpg';
import elevator07 from '../../assets/images/type_elevator07.jpg';
import elevator08 from '../../assets/images/type_elevator08.jpg';
import elevator09 from '../../assets/images/type_elevator09.jpg';

import tkj001 from '../../assets/images/tkj001.webp';
import tkj007 from '../../assets/images/tkj007.webp';
import tkj006 from '../../assets/images/tkj006.webp';
import tkj013 from '../../assets/images/tkj013.webp';
import tkj008 from '../../assets/images/tkj008.webp';
import tkj009 from '../../assets/images/tkj009.webp';
import tkj015 from '../../assets/images/tkj015.webp';
import tkj016 from '../../assets/images/tkj016.webp';
import tkj011 from '../../assets/images/tkj011.webp';
import tkj004 from '../../assets/images/tkj004.webp';
import tkj017 from '../../assets/images/tkj017.webp';
import tkj0161 from '../../assets/images/tkj0161.webp';

import passenger211 from '../../assets/images/passenger211.webp';
import passenger212 from '../../assets/images/passenger212.webp';
import passenger221 from '../../assets/images/passenger221.webp';
import passenger222 from '../../assets/images/passenger222.webp';
import passenger23 from '../../assets/images/passenger23.webp';

import passenger31 from '../../assets/images/passenger31.webp';
import passenger32 from '../../assets/images/passenger32.webp';
import passenger33 from '../../assets/images/passenger33.webp';
import passenger34 from '../../assets/images/passenger34.webp';

import passenger41 from '../../assets/images/passenger41.webp';
import passenger51 from '../../assets/images/passenger51.webp';
import passenger61 from '../../assets/images/passenger61.webp';

import passenger711 from '../../assets/images/passenger711.webp';
import passenger712 from '../../assets/images/passenger712.webp';
import passenger713 from '../../assets/images/passenger713.webp';
import passenger714 from '../../assets/images/passenger714.webp';
import passenger721 from '../../assets/images/passenger721.webp';
import passenger722 from '../../assets/images/passenger722.webp';
import passenger723 from '../../assets/images/passenger723.webp';

import passenger81 from '../../assets/images/passenger81.webp';
import passenger82 from '../../assets/images/passenger82.webp';
import passenger91 from '../../assets/images/passenger91.webp';
const slides = [
  { img: elevator01, text: 'Car Elevator' },
  { img: elevator02, text: 'Hydraulic Elevators' },
  { img: elevator03, text: 'Escalator & Moving Walk' },
  { img: elevator04, text: 'Dumbwaiter' },
  { img: elevator05, text: 'Passenger Elevator' },
  { img: elevator06, text: 'Observation Elevator' },
  { img: elevator07, text: 'Freight Elevator' },
  { img: elevator08, text: 'Hospial Elevator' },
  { img: elevator09, text: 'Home Elevator' }
]
const { Header, Content, Footer } = Layout;
class Passenger extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Passenger Elevator',
      header_title: 'Passenger elevator',
      header_content: 'Fuji passenger elevator / Passenger lift  / Ascenseurs / Ascensor de pasajeros -- Low noise, vector control inverter, VVVF, which are the most important signs for modern high-quality elevator. Owing to the use of state-of-the-art IGBT, which makes the converter achieve PWM control for high carrier frequency, reduces the noise of motor greatly, and it has small power consumption and long service life. In order to meet the needs of modern society, at the same time, in consideration of human physical limit, FUJI elevator uses microprocessor for obtaining optimal operation line, provides with very comfortable and stable operation, all these are the result of high technology, and it can send the passenger to a high-rise building comfortably and quickly!',
      top1_titel: '1. Car design',
      top2_titel: '2. Human-Machine Exchange System',
      top3_titel: '3. Optional Handrail Designs: ',
      top4_titel: '4. Optional Car Ceiling designs',
      top5_titel: '5. Optional Car Door and Landing Door Design ( Standard narrow jamb, wide jamb could be chose).',
      top6_titel: '6. Optional Car Flooring',
      top7_titel: '7. Hoistway Construction Layout  Drawing and  Technical Specifications:',
      top8_titel: '8. Standard Functions:',
      top9_titel: '9. Optional Functions:',

      top1_content: "Fuji Elevator's 'human-oriented' concept combines with unique design and lovely ornament. FUJI Elevator studies the relations between vision, audition, touch sensation and human, and reaches harmony unification of use function and esthetic feeling in a relatively-closed space. All these are expressions of human natures, and thus meet the needs that human pursue for a comfortable and unique living environment. All these reflect that Fuji Elevator dedicates itself to looking for ways to show its respect for human natures.",
      top2_content: [
        'Optional car signal device (COP) and Hall signal device (LOP).',
        'Car operation panel(COP) buttons and landing call buttons are designed according to ergonomics theory, which have good feeling of hand touch. You just need touch the button softly and it will send control command. The display can indicate all numbers and symbols to be needed clearly.',
        'COP (Car Operating Panel) and LOP (Hall Buttons) material: Stainless steel or tempered glass. There are four types of stainless steel material COPs for choices: (a). Hairline, (b). Mirror finish (c). Titanium, (d). Mirror finish in both two sides and hairline in middle part. For tempered glass material COP, you can decide what color you want.',
      ],
      top1_tabs: [
        {
          title: '1). Standard series -- Classic decoration, comfortable experience.',
          imgs: [
            { img: tkj001, text: 'Model: FJ-TKJ-001' },
            { img: tkj007, text: 'Model: FJ-TKJ-007' },
            { img: tkj006, text: 'Model: FJ-TKJ-006' },
            { img: tkj013, text: 'Model: FJ-TKJ-013' }
          ],
          Features: ['Car side wall panel: Hairline stainless steel. ',
            'Car rear wall panel:  Hairline stainless steel or etch/mirror/hairline stainless steel.',
            'Car front wall panel: Hairline stainless steel.',
            'Car ceiling: Hairline stainless frame with LED energy saving lighting.',
            'Car flooring: Marble-imitated PVC or real marble.'
          ]
        },
        {
          title: '2). Commercial series -- High-end, elegant appearance.',
          imgs: [
            { img: tkj008, text: 'Model: FJ-TKJ-008' },
            { img: tkj009, text: 'Model: FJ-TKJ-009' },
            { img: tkj015, text: 'Model: FJ-TKJ-015' },
            { img: tkj016, text: 'Model: FJ-TKJ-013' }
          ],
          Features: ['Car side wall panel: Hairline stainless steel+ mirror (+ etching).',
            'Car rear wall panel:  Hairline stainless steel+ mirror (+ etching).',
            'Car front wall panel: Hairline stainless steel.',
            'Car ceiling: Mirror / hairline stainless frame with LED energy saving lighting.',
            'Car flooring: Marble-imitated PVC or or real marble.'
          ]
        },
        {
          title: '3). Luxurious series -- High-end, elegant appearance. ',
          imgs: [
            { img: tkj011, text: 'Model: FJ-TKJ-011' },
            { img: tkj004, text: 'Model: FJ-TKJ-004' },
            { img: tkj017, text: 'Model: FJ-TKJ-017' },
            { img: tkj0161, text: 'Model: FJ-TKJ-016' }
          ],
          Features: ['Car side wall panel: Hairline stainless steel+ mirror + etching + titanium.',
            'Car rear wall panel:  Hairline stainless steel+ mirror + etching + titanium.',
            'Car front wall panel: Hairline stainless steel+ etching + titanium.',
            'Car ceiling: Mirror / hairline stainless frame with LED energy saving lighting.',
            'Car flooring: Marble-imitated PVC or real marble.'
          ]
        },
      ],
      top2_tabs: [
        {
          title: '1).  Optional COP designs：',
          imgs: [
            { img: passenger211, texts: ['FJ-C11', 'FJ-C02', 'FJ-C21', 'FJ-C20', 'FJ-C01', 'FJ-C14', 'FJ-C08', 'FJ-C04', 'FJ-C06', 'FJ-C05', 'FJ-HC01'] },
            { img: passenger212, texts: ['FJ-C02', 'FJ-C11', 'FJ-C11', 'FJ-C22', 'FJ-C23', 'FJ-C14', 'FJ-C17', 'FJ-C17', 'FJ-C17', 'FJ-C03', 'FJ-C16'] }
          ],
        },
        {
          title: '2).  Optional Hall Buttons (LOP) designs:',
          imgs: [
            { img: passenger221, texts: ['FJ-Z11', 'FJ-Z02', 'FJ-Z21', 'FJ-Z20', 'FJ-Z04', 'FJ-Z06', 'FJ-Z12', 'FJ-Z01', 'FJ-C08', 'FJ-Z05'] },
            { img: passenger222, texts: ['FJ-Z14', 'FJ-Z02', 'FJ-Z23', 'FJ-Z17', 'FJ-Z17', 'FJ-Z03', 'FJ-Z17', 'FJ-Z17-2	', 'FJ-Z14-2	', 'FJ-Z23-2'] }
          ],
        },
        {
          title: '3). Optional display screen /indicator designs on COP and LOP :',
          imgs: [
            { img: passenger23, texts: ['Dot matrix display	', 'Blue/white LCD', '5.7" LED back light LCD', '7" or 9.7" TFT multi color LCD  display', '4.3"TFT'] },
          ],
        }
      ],
      top2_points: '► Display screen type : You can select one type from red dot-matrix display, blue/white color LCD display and TFT multi color LCD display.',
      top3_tabs: [
        {
          img: passenger31, texts: ['FJ-FS01', 'FJ-FS02'],
        },
        {
          img: passenger32, texts: ['FJ-FS05', 'FJ-FS06'],
        },
        {
          img: passenger33, texts: ['FJ-FS08', 'FJ-FS09'],
        },
        {
          img: passenger34, texts: ['FJ-FS03', 'FJ-FS04'],
        }
      ],
      top4_tabs: {
        img: passenger41, texts: ['FJ-D34', 'FJ-D06', 'FJ-D07', 'FJ-D08', 'FJ-D09', 'FJ-D10', 'FJ-D11'],
      },
      top5_tabs: {
        img: passenger51,
        texts: [
          { text0: 'FJ-TM01', text1: 'Hairline ST / ST' },
          { text0: 'FJ-TM02', text1: 'Painted plate' },
          { text0: 'FJ-TM13', text1: 'Mirror / etching' },
          { text0: 'FJ-TM03', text1: 'Mirror / etching' },
          { text0: 'FJ-TM12', text1: 'Glass / hairline' },
          { text0: 'FJ-TM04', text1: 'Titanium / etching / mirror' },
          { text0: 'FJ-TM20', text1: 'Titanium / etching / mirror' },
        ],
      },
      top6_tabs: {
        img: passenger61, texts: ['FJ-JD01', 'FJ-JD02', 'FJ-JD05', 'FJ-JD06', 'FJ-JD07', 'FJ-JD09', 'FJ-JD19', 'FJ-JD18', 'FJ-JD08'],
      },
      top7_tabs: [
        {
          title: '(1). Small Machine Room (MR) Passenger Elevator', img: [passenger711, passenger712, passenger713, passenger714]
        },
        {
          title: '(2).Machine Room less (MRL) Passenger Elevator', img: [passenger721, passenger722, passenger723]
        },
      ],
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { code } = this.state;
    console.log(this.state);
    return (
      <Layout style={{ background: '#FFFFFF' }}>

        <Content className="site-layout" style={{ padding: '1rem' }}>
          <div>
            <h2>{this.state.header_title}</h2>
            <p>{this.state.header_content}</p>
            <div className='ele-img'><img src={elevator05} /></div>

          </div>
          <div>
            <h4>{this.state.top1_titel}</h4>
            <p>{this.state.top1_content}</p>
            {
              this.state.top1_tabs.map((item, indexs) => {
                return (<div key={indexs}>
                  <h4>{item.title}</h4>
                  <Row justify='center' gutter={[{ xs: 4, sm: 8, md: 16, lg: 24 }, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
                    {
                      item.imgs.map((val, element) => {
                        return (<Col key={element} span={6}> <img style={{ width: '100%' }} src={val.img} /><p style={{ textAlign: 'center' }}>{val.text}</p></Col>)
                      })
                    }
                  </Row>
                  <p style={{ margin: '1rem 0 0 0' }}>Features:</p>
                  <div>
                    {
                      item.Features.map((val, element) => {
                        return <p key={element}>{val}</p>
                      })
                    }
                  </div>


                </div>)
              })
            }
          </div>
          <div>
            <h4>{this.state.top2_titel}</h4>
            {/* <p>{this.state.top2_content}</p> */}
            <div>
              {
                this.state.top2_content.map((val, element) => {
                  return <p key={element}>{val}</p>
                })
              }
            </div>
            {
              this.state.top2_tabs.map((item, indexs) => {
                return (<div key={indexs}>
                  <h4>{item.title}</h4>
                  {
                    item.imgs.map((val, element) => {
                      return (<div key={element} style={{ paddingBottom: '1rem' }}>
                        <img src={val.img} />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          {val.texts.map((e, num) => {
                            return (<p style={{ textAlign: 'center' }} key={num}>{e}</p>)
                          })}

                        </div>
                      </div>)
                    })
                  }

                </div>)
              })
            }
            <p>{this.state.top2_points}</p>
          </div>

          <div>
            <h4>{this.state.top3_titel}</h4>
            <Row justify='center' gutter={[{ xs: 4, sm: 8, md: 16, lg: 24 }, { xs: 4, sm: 8, md: 16, lg: 24 }]}>
              {
                this.state.top3_tabs.map((item, indexs) => {
                  return (<Col span={12} key={indexs}>
                    <img src={item.img} />
                    <div className='img-text'>
                      <p>{item.texts[0]}</p>
                      <p>{item.texts[1]}</p>
                    </div>
                  </Col>)
                })
              }


            </Row>
          </div>
          <div>
            <h4>{this.state.top4_titel}</h4>
            <div>
              <img src={this.state.top4_tabs.img} />
              <div className='img-text'>
                {
                  this.state.top4_tabs.texts.map((item, indexs) => {
                    return (
                      <p key={indexs}>{item}</p>)
                  })
                }
              </div>
            </div>
          </div>

          <div>
            <h4>{this.state.top5_titel}</h4>
            <div>
              <img src={this.state.top5_tabs.img} />
              <div className='img-text'>
                {
                  this.state.top5_tabs.texts.map((item, indexs) => {
                    return (<div key={indexs}><p style={{ textAlign: 'center' }}>{item.text0}</p><p style={{ textAlign: 'center' }}>{item.text1}</p></div>
                    )
                  })
                }
              </div>
            </div>
          </div>

          <div>
            <h4>{this.state.top6_titel}</h4>
            <div>
              <img src={this.state.top6_tabs.img} />
              <div className='img-text'>
                {
                  this.state.top6_tabs.texts.map((item, indexs) => {
                    return (
                      <p key={indexs}>{item}</p>)
                  })
                }
              </div>
            </div>
          </div>

          <div>
            <h4>{this.state.top7_titel}</h4>
            {this.state.top7_tabs.map((item,indexs)=>{
              return(<div key={indexs}>
              <p>{item.title}</p>
              <div><img src={item.img[0]} /></div>
              <div><img src={item.img[1]} /></div>
              <div><img src={item.img[2]} /></div>
              <div><img src={item.img[3]} /></div>
              

            </div>
              )
            })}
            
          </div>
          
          <div>
            <h4>{this.state.top8_titel}</h4>
            <div><img src={passenger81}/></div>
            <div><img src={passenger82}/></div>
          </div>

          <div>
            <h4>{this.state.top9_titel}</h4>
            <div><img src={passenger91}/></div>
            
          </div>

        </Content>
      </Layout>
    )
  }
}


export default Passenger;
