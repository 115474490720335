

import React, { useEffect, useState } from 'react';
import { Button, Input, InputNumber, Image, Row, Col, message, Select } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import titletop from '../../assets/images/titletop.png';
import title from '../../assets/images/title.png';
import titleback from '../../assets/images/titleback.png';
import axios from 'axios';
import './index.scss';
const { Option } = Select;
const { TextArea } = Input;

// const BASE_URL = 'http://192.168.0.105:8000';
const BASE_URL = 'https://333.shulan-tech.com';
// const next_url = 'http://192.168.0.111:3000'
const next_url = 'https://www.fujielevatorcn.com'
const messagesave = `${BASE_URL}/applets/elevatorcertificate/save`;
const listsave = `${BASE_URL}/applets/elevatorcomponent/save`;
const update = `${BASE_URL}/applets/elevatorcertificate/update`;
const generateQRCode = `${BASE_URL}/applets/codeGenerator/generateQRCode`;



const initialData = [
  { componentName: '曳引机', componentModel: '', componentNumber: '' },
  { componentName: '制动器', componentModel: '', componentNumber: '' },
  { componentName: '轿厢意外移动保护装置', componentModel: '', componentNumber: '' },
  { componentName: '上行超速保护装置', componentModel: '', componentNumber: '' },
  { componentName: '控制柜', componentModel: '', componentNumber: '' },
  { componentName: '限速器', componentModel: '', componentNumber: '' },
  { componentName: '缓冲器', componentModel: '', componentNumber: '' },
  { componentName: '安全钳', componentModel: '', componentNumber: '' },
  { componentName: '层门', componentModel: '', componentNumber: '' },
  { componentName: '层门锁', componentModel: '', componentNumber: '' },
  { componentName: '轿门锁', componentModel: '', componentNumber: '' },
  { componentName: '含有电子元件的安全回路', componentModel: '', componentNumber: '' },
  { componentName: '含有电子元件的安全回路2', componentModel: '', componentNumber: '' },
  { componentName: '停电应急平层', componentModel: '', componentNumber: '' },
  { componentName: '绳头组合', componentModel: '', componentNumber: '' },
  { componentName: '悬挂装置', componentModel: '', componentNumber: '' },
];
const DataRow = ({ label, componentModel, componentNumber, disabled, inputChange, setpp1, mianfontsizes, miantitheight }) => (
  <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
    <div style={{ width: '33.3%', textAlign: 'center', lineHeight: miantitheight, fontSize: mianfontsizes, borderRight: `${setpp1} solid #000` }}>
      <span>{label}</span>
    </div>
    <div style={{ width: '33.3%', height: '100%', textAlign: 'center', lineHeight: miantitheight, fontSize: mianfontsizes, borderRight: `${setpp1} solid #000` }}>
      <Input className='inputss' disabled={disabled} value={componentModel} onChange={(e) => inputChange(e, 'componentModel', label)} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
    </div>
    <div style={{ width: '33.4%', height: '100%', textAlign: 'center', fontSize: mianfontsizes, lineHeight: miantitheight }}>
      <Input className='inputs' disabled={disabled} value={componentNumber} onChange={(e) => inputChange(e, 'componentNumber', label)} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
    </div>
  </div>
);

const MainComponent = ({ data, setpp1, mianfontsizes, miantitheight, inputChange, disabled }) => (
  <div style={{ borderBottom: `${setpp1} solid #000` }}>
    {data.map((item, index) => (
      <DataRow
        key={index}
        label={item.componentName}
        componentModel={item.componentModel}
        componentNumber={item.componentNumber}
        disabled={disabled}
        inputChange={inputChange}
        setpp1={setpp1}
        mianfontsizes={mianfontsizes}
        miantitheight={miantitheight}
      />
    ))}
  </div>
);
const About = () => {
  const [datas, setDatas] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [imgsrc, setImgsrc] = useState('');
  const [datanew, setDatanew] = useState(initialData);
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedCompany, setSelectedCompany] = useState('');
  const inputChange = (e, property) => {
    setDatas((prevDatas) => ({
      ...prevDatas,
      [property]: e.target.value,
    }));
  };
  const inputChanges = (e, property) => {
    if (!isNaN(e)) {
      setDatas((prevDatas) => ({
        ...prevDatas,
        [property]: e
      }));
    } else {
      return (message.warning('请输入有效的数字'))
    }
  };



  // const handleCompanyChange = (value) => {
  //   setSelectedCompany(value);
  // };
  const handleCompanyChange = (e, property) => {
    setDatas((prevDatas) => ({
      ...prevDatas,
      ['corporateName']: e.target.value,
    }));
  };
  const inputChangenew = (e, type, label) => {
    const newValue = e.target.value;
    setDatanew(datanew.map(item => item.componentName === label ? { ...item, [type]: newValue } : item));
  };


  useEffect(() => {
    setDatas((prevDatas) => ({
      ...prevDatas,
      ['corporateName']: '安徽富控',
    }));
    // 在组件加载后发送 GET 请求获取数据disabled
    // const fetchData = async () => {
    //   try {
    //     const response = await axios.get(`https://333.shulan-tech.com/applets/elevatorcertificate/info/${id}`);
    //     // 更新 state，处理获取到的数据
    //     if (response.data.elevatorCertificate && response.data.elevatorCertificate != '') {
    //       setDatas(response.data.elevatorCertificate);
    //       setDisabled(true)
    //     } else {
    //       setDisabled(false)
    //     }
    //   } catch (error) {
    //   }
    // };
    // fetchData();
  }, [id]); // 当 id 参数变化时重新发送请求
  const finish = () => {
    axios.post(messagesave, datas)
      .then(response => {
        const certificateId = response.data.id;

        const updatedDataNew = datanew.map(item => ({
          ...item,
          certificateId: certificateId
        }));
        setTimeout(() => {
          axios.post(listsave, updatedDataNew)
            .then(ress => {
              console.log(ress, 'listsave');
            })
            .catch(error => {
              console.error('请求出错', error);
            });

          axios.post(generateQRCode, `${next_url}/hold/${response.data.id}`,
            {
              headers: {
                'Content-Type': 'text/plain',
              },
            }
          )
            .then(res => {
              if (res.data.code === 200) {
                const link = `/hold/${response.data.id}`;
                // window.open(link,'_blank');
                const imgsrc = 'https://444.shulan-tech.com' + res.data.path
                setImgsrc(imgsrc)
                setDatas((prevDatas) => ({
                  ...prevDatas,
                  url: imgsrc
                }));
                setTimeout(() => {
                  axios.post(update, { id: response.data.id, url: imgsrc })
                    .then(responses => {
                      if (responses.data.code === 200) {
                        setTimeout(() => {
                          navigate(link);
                        }, 100);
                      }
                    })
                    .catch(error => {
                      console.error('请求出错', error);
                    });
                }, 100);
              }
            })
            .catch(error => {
              console.error('请求出错', error);
            });
        }, 100);
      })
      .catch(error => {
        console.error('请求出错', error);
      });

  };
  const innerHeight = window.innerHeight;
  const innerWidth = window.innerWidth;
  let titlefont;
  let itemsfont;
  let itemsfonts;
  let jianyanfont;
  let bodypadding;
  let inputwidth;
  let inputwidths;
  let inputheight;
  let imgmarl;
  let imgmarls;
  let itemsleftpadle;
  let itemsrightpadto;
  let itemsleftpadri;
  let itemsitemsbot;
  let mianfontsize;
  let mianfontsizes;
  let mianinputpaddings;
  let mianinputpadding;
  let miantitheight;
  let ewmmainhei;
  let miantitwidth;
  let mianinputwidth;
  let ewmwidth;
  let mianinputwidthend;
  let setpp85;
  let setpp70;
  let setpp170;
  let setpps170;
  let setpp250;
  let setpp20;
  let setpp366;
  let setpp715;
  let setpp10;
  let setpp37;
  let setpp1088;
  let setpps1088;
  let setpp50;
  let setpp24;
  let setpp30;
  let setpp1;
  let setpp75;
  let setpp36;
  let setpp113;
  let setpp280;
  let setpp310;
  let imageohei;
  let imageoheis;
  let setpp1895;


  const zoomfontsize = innerWidth / 1920;
  const entryName = datas?.entryName || '';
  const productName = datas?.productName || '';
  const model = datas?.model || '';
  const factoryNumber = datas?.factoryNumber || '';
  const data = datas?.data || '';
  const address = datas?.address || '';
  const zipcode = datas?.zipcode || '';
  const phone = datas?.phone || '';
  const effectiveDate = datas?.effectiveDate || '';
  const licenseNumber = datas?.licenseNumber || '';
  const equipmentCode = datas?.equipmentCode || '';
  const weight = datas?.weight || null;
  const speed = datas?.speed || '';
  const layer = datas?.layer || '';
  const corporateName = datas?.corporateName || '安徽富控';
  const companySeal = datas?.companySeal || '';
  const openingWidth = datas?.openingWidth || null;
  const openMode = datas?.openMode || '';
  const controlMode = datas?.controlMode || '';
  const openingHeight = datas?.openingHeight || null;
  const carSize = datas?.carSize || '';
  const url = datas?.url || '';
  let isiOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
  let divStyle;
  if (isiOS) {
    // 如果isiOS为true时的样式

    titlefont = 110 / 1920 * innerWidth + 'px';
    itemsfont = 32 / 1920 * innerWidth + 'px';
    itemsfonts = 28 / 1920 * innerWidth + 'px';
    jianyanfont = 36 / 1920 * innerWidth + 'px';
    bodypadding = 15 / 1920 * innerWidth + 'px';
    inputwidth = 355 / 1920 * innerWidth + 'px';
    inputwidths = 250 / 1920 * innerWidth + 'px';
    inputheight = 42 / 1920 * innerWidth + 'px';
    imgmarl = 200 / 1920 * innerWidth + 'px';
    imgmarls = 210 / 1920 * innerWidth + 'px';
    itemsleftpadle = 180 / 1920 * innerWidth + 'px';
    itemsrightpadto = 28 / 1920 * innerWidth + 'px';
    itemsleftpadri = 84 / 1920 * innerWidth + 'px';
    itemsitemsbot = 10 / 1920 * innerWidth + 'px';
    mianfontsize = 22 / 1920 * innerWidth + 'px';
    mianfontsizes = 18 / 1920 * innerWidth + 'px';
    mianinputpaddings = 40 / 1920 * innerWidth + 'px';
    mianinputpadding = 8 / 1920 * innerWidth + 'px';
    miantitheight = 58 / 1920 * innerWidth + 'px';
    ewmmainhei = 300 / 1920 * innerWidth + 'px';
    miantitwidth = 160 / 1920 * innerWidth + 'px';
    mianinputwidth = 160 / 1920 * innerWidth + 'px';
    ewmwidth = 150 / 1920 * innerWidth + 'px';
    mianinputwidthend = 260 / 1920 * innerWidth + 'px';
    setpp85 = 85 / 1920 * innerWidth + 'px';
    setpp70 = 70 / 1920 * innerWidth + 'px';
    setpp170 = 100 / 1920 * innerWidth + 'px';
    setpps170 = 150 / 1920 * innerWidth + 'px';
    setpp250 = 250 / 1920 * innerWidth + 'px';
    setpp20 = 20 / 1920 * innerWidth + 'px';
    setpp366 = 366 / 1920 * innerWidth + 'px';
    setpp715 = 715 / 1920 * innerWidth + 'px';
    setpp10 = 10 / 1920 * innerWidth + 'px';
    setpp37 = 37 / 1920 * innerWidth + 'px';
    setpp1088 = 1080 / 1920 * innerWidth + 'px';
    setpps1088 = 1290 / 1920 * innerWidth + 'px';
    setpp50 = 50 / 1920 * innerWidth + 'px';
    setpp24 = 24 / 1920 * innerWidth + 'px';
    setpp30 = 30 / 1920 * innerWidth + 'px';
    setpp1 = 1 / 1920 * innerWidth + 'px';
    setpp75 = 10 / 1920 * innerWidth + 'px';
    setpp36 = 36 / 1920 * innerWidth + 'px';
    setpp113 = 113 / 1920 * innerWidth + 'px';
    setpp280 = 280 / 1920 * innerWidth + 'px';
    setpp310 = 320 / 1920 * innerWidth + 'px';
    imageohei = 1300 / 1920 * innerWidth + 'px';
    imageoheis = 1290 / 1920 * innerWidth + 'px';
    setpp1895 = 1895 / 1920 * innerWidth + 'px';
    divStyle = {
      overflow: 'hidden',
      width: setpp1895,
      textAlign: 'left',
      transform: 'scale(1)'
    };
  } else {
    // 如果isiOS为false时的样式

    titlefont = 110 + 'px';
    itemsfont = 32 + 'px';
    itemsfonts = 28 + 'px';
    jianyanfont = 36 + 'px';
    bodypadding = 15 + 'px';
    inputwidth = 355 + 'px';
    inputwidths = 250 + 'px';
    inputheight = 42 + 'px';
    imgmarl = 200 + 'px';
    imgmarls = 230 + 'px';
    itemsleftpadle = 180 + 'px';
    itemsrightpadto = 28 + 'px';
    itemsleftpadri = 84 + 'px';
    itemsitemsbot = 10 + 'px';
    mianfontsize = 22 + 'px';
    mianfontsizes = 18 + 'px';
    mianinputpaddings = 40 + 'px';
    mianinputpadding = 8 + 'px';
    miantitheight = 58 + 'px';
    ewmmainhei = 300 + 'px';
    miantitwidth = 160 + 'px';
    mianinputwidth = 160 + 'px';
    ewmwidth = 150 + 'px';
    mianinputwidthend = 260 + 'px';
    setpp85 = 65 + 'px';
    setpp70 = 70 + 'px';
    setpp170 = 110 + 'px';
    setpps170 = 170 + 'px';
    setpp250 = 250 + 'px';
    setpp20 = 20 + 'px';
    setpp366 = 366 + 'px';
    setpp715 = 715 + 'px';
    setpp10 = 10 + 'px';
    setpp37 = 37 + 'px';
    setpp1088 = 1040 + 'px';
    setpps1088 = 1100 + 'px';
    setpp50 = 50 + 'px';
    setpp24 = 24 + 'px';
    setpp30 = 30 + 'px';
    setpp1 = 1 + 'px';
    setpp75 = 50 + 'px';
    setpp36 = 36 + 'px';
    setpp113 = 113 + 'px';
    setpp280 = 280 + 'px';
    setpp310 = 320 + 'px';
    imageohei = 1300 + 'px';
    imageoheis = 1200 + 'px';
    setpp1895 = 1895 + 'px';
    divStyle = {
      overflow: 'hidden',
      width: setpp1895,
      textAlign: 'left',
      zoom: zoomfontsize
    };
  }
  return (
    <div style={divStyle}>
      <Row className='image_o' style={{ backgroundSize: `100% ${imageohei}` }} justify='center'  >
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xxl={{ span: 24 }}>
          <div style={{ position: 'relative', padding: `${setpp170} ${setpps170}`, height: setpp1088 }}>
            <Image style={{ width: setpp250, position: 'absolute', left: '0', top: '0' }} src={logo} preview={false} />
            <Row justify='center' >
              <Col span={16} className='titlebody'>
                <Image style={{ width: setpp366, marginBottom: setpp20 }} src={titletop} preview={false} />
                <span style={{ fontSize: titlefont }} className='title'>产品质量合格证书</span>
                <Image className='titleback' style={{ width: setpp715, paddingRight: setpp37, marginTop: setpp30 }} src={title} preview={false} />
              </Col>
            </Row>
            <Row justify='center' style={{ marginTop: setpp50, paddingLeft: bodypadding, paddingRight: bodypadding }} >
              <Col span={21} className='bodys' style={{ fontSize: itemsfont }}>
                <div className='inputs' >
                  <span>
                    项目名称：
                  </span>
                  <Input className='inputs' disabled={disabled} value={entryName} onChange={(e) => inputChange(e, 'entryName')} style={{ maxWidth: inputwidths, height: inputheight, fontSize: itemsrightpadto }} placeholder="" />
                </div>
              </Col>
              <Col span={21} className='bodys' style={{ fontSize: itemsfont, display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: inputheight }}>
                <div className='inputs'>
                  <span>
                    产品名称：
                  </span>
                  <Input className='inputs' disabled={disabled} value={productName} onChange={(e) => inputChange(e, 'productName')} style={{ maxWidth: inputwidths, height: inputheight, fontSize: itemsrightpadto }} placeholder="" />
                </div>
                <div className='inputs'>
                  <span>
                    产品型号：
                  </span>
                  <Input className='inputs' disabled={disabled} value={model} onChange={(e) => inputChange(e, 'model')} style={{ maxWidth: inputwidths, height: inputheight, fontSize: itemsrightpadto }} placeholder="" />
                </div>
                <div className='inputs'>
                  <span>
                    出厂编号：
                  </span>
                  <Input className='inputs' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ maxWidth: inputwidths, height: inputheight, fontSize: itemsrightpadto }} placeholder="" />
                </div>
              </Col>
              <Col span={21} className='bodycontent' style={{ fontSize: itemsfont, marginTop: setpp85 }}>
                <div >
                  <span className='bodycontents' >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本产品经检验，其安全性能和技术参数符合GB/T7588.1-2020《电梯制造与安装安全规范》、GB/T10058-2009《电梯技术条件》和GB/T10060-2011《电梯安装验收规范》的标准规定，检验合格，准予出厂。
                  </span>
                </div>
              </Col>
              <Col span={21} className='bodybottom' >
                <Row justify='center' style={{ marginTop: setpp50 }} >
                  <Col span={9} className='footleft' style={{ fontSize: jianyanfont, display: 'flex', alignItems: 'center' }}>
                    <span>检验合格章</span>
                    {
                      corporateName == '安徽富控' ?
                        <Image preview={true} src='https://444.shulan-tech.com/data/elevator/anhui.png' style={{ marginTop: itemsitemsbot }} width={imgmarls} />
                        :
                        <Image preview={true} src='https://444.shulan-tech.com/data/elevator/jiangxi.png' style={{ marginTop: itemsitemsbot }} width={imgmarls} />
                    }
                  </Col>
                  <Col span={15} className='footright' style={{ fontSize: itemsfont }}>
                    <div className='inputs time' style={{ marginBottom: setpp50 }}>
                      <span>
                        出厂日期：
                      </span>
                      <Input className='inputs' disabled={disabled} value={data} onChange={(e) => inputChange(e, 'data')} style={{ maxWidth: inputwidth, height: inputheight, fontSize: itemsrightpadto }} placeholder="" />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <select style={{ fontSize: itemsfont, background: 'rgb(255,255,255,0)', color: '#000' }} onChange={handleCompanyChange} id="companySelect">
                        <option value="安徽富控" selected>安徽富控</option>
                        <option value="江西富士">江西富士</option>
                      </select>
                      <span className="company" style={{ fontSize: jianyanfont }}>电梯有限公司</span>
                    </div>


                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className='image_t' style={{ backgroundSize: `100% ${imageoheis}` }} justify='center' >
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xxl={{ span: 24 }}>
          <div style={{ position: 'relative', padding: `${setpp75} ${setpp36}`, height: setpps1088 }}>
            <Image style={{ width: setpp250, position: 'absolute', left: '0', top: '0' }} src={logo} preview={false} />
            <Row justify='center' >
              <Col span={9} className='itemsleft' style={{ paddingTop: itemsleftpadle, paddingLeft: setpp113, fontSize: itemsfonts }}>
                <div className='inputs' style={{ marginBottom: itemsitemsbot }}>
                  <span>
                    项目名称：
                  </span>
                  <Input className='inputs' disabled={disabled} value={entryName} onChange={(e) => inputChange(e, 'entryName')} style={{ maxWidth: inputwidth, height: inputheight, fontSize: itemsrightpadto }} placeholder="" />
                </div>
                <div className='inputs' style={{ marginBottom: itemsitemsbot }}>
                  <span>
                    产品名称：
                  </span>
                  <Input className='inputs' disabled={disabled} value={productName} onChange={(e) => inputChange(e, 'productName')} style={{ maxWidth: inputwidth, height: inputheight, fontSize: itemsrightpadto }} placeholder="" />
                </div>
                <div className='inputs' style={{ marginBottom: itemsitemsbot }}>
                  <span>
                    产品型号：
                  </span>
                  <Input className='inputs' disabled={disabled} value={model} onChange={(e) => inputChange(e, 'model')} style={{ maxWidth: inputwidth, height: inputheight, fontSize: itemsrightpadto }} placeholder="" />
                </div>
                <div className='inputs' style={{ marginBottom: itemsitemsbot }}>
                  <span>
                    出厂编号：
                  </span>
                  <Input className='inputs' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ maxWidth: inputwidth, height: inputheight, fontSize: itemsrightpadto }} placeholder="" />
                </div>
                <div className='inputs' style={{ marginBottom: itemsitemsbot }}>
                  <span>
                    地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：
                  </span>
                  {/* <Input className='inputs' disabled={disabled} value={address} onChange={(e) => inputChange(e, 'address')} style={{ minWidth: inputwidth, height: inputheight, fontSize: itemsrightpadto }} placeholder="" /> */}
                  <Input.TextArea
                    className='inputs'
                    disabled={disabled}
                    value={address}
                    onChange={(e) => inputChange(e, 'address')}
                    style={{ maxWidth: inputwidth, height: inputheight, fontSize: itemsrightpadto }}
                    placeholder=""
                    autoSize={{ minRows: 1, maxRows: 6 }}
                  />
                </div>
                <div className='inputs' style={{ marginBottom: itemsitemsbot, display: 'flex', alignItems: 'center' }}>
                  <span>
                    邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;编：
                  </span>
                  <Input className='inputs' disabled={disabled} value={zipcode} onChange={(e) => inputChange(e, 'zipcode')} style={{ maxWidth: inputwidth, height: inputheight, fontSize: itemsrightpadto }} placeholder="" />
                </div>
                <div className='inputs' style={{ marginBottom: itemsitemsbot }}>
                  <span>
                    电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：
                  </span>
                  <Input className='inputs' disabled={disabled} value={phone} onChange={(e) => inputChange(e, 'phone')} style={{ maxWidth: inputwidth, height: inputheight, fontSize: itemsrightpadto }} placeholder="" />
                </div>

                <div className='inputs' style={{ marginBottom: itemsitemsbot, marginRight:itemsitemsbot }}>
                  <span>
                    特种设备制造许可证编号：
                  </span>
                  {/* <Input className='inputs' disabled={disabled} value={licenseNumber} onChange={(e) => inputChange(e, 'licenseNumber')} style={{ maxWidth: inputwidth, height: inputheight, fontSize: itemsrightpadto }} placeholder="" /> */}
                  <Input.TextArea
                    className='inputs'
                    disabled={disabled}
                    value={licenseNumber}
                    onChange={(e) => inputChange(e, 'licenseNumber')}
                    style={{ maxWidth: inputwidth, height: inputheight, fontSize: itemsrightpadto }}
                    placeholder=""
                    autoSize={{ minRows: 1, maxRows: 6 }}
                  />
                </div>
                <div className='inputs' style={{ marginBottom: itemsitemsbot }}>
                  <span>
                    有效日期至：
                  </span>
                  <Input className='inputs' disabled={disabled} value={effectiveDate} onChange={(e) => inputChange(e, 'effectiveDate')} style={{ maxWidth: inputwidth, height: inputheight, fontSize: itemsrightpadto }} placeholder="" />
                </div>
                {/* <div className='inputs' style={{ marginBottom: itemsleftpadle }}> */}
                <div className='inputs' style={{ marginBottom: itemsitemsbot }}>
                  <span>
                    生产设备代码：
                  </span>
                  {/* <Input className='inputs' disabled={disabled} value={equipmentCode} onChange={(e) => inputChange(e, 'equipmentCode')} style={{ maxWidth: inputwidth, height: inputheight, fontSize: itemsrightpadto }} placeholder="" /> */}
                  <Input.TextArea
                    className='inputs'
                    disabled={disabled}
                    value={equipmentCode}
                    onChange={(e) => inputChange(e, 'equipmentCode')}
                    style={{ maxWidth: inputwidth, height: inputheight, fontSize: itemsrightpadto }}
                    placeholder=""
                    autoSize={{ minRows: 1, maxRows: 6 }}
                  />
                </div>
                <div className='inputmain' style={{ alignItems: 'start', marginTop: itemsitemsbot }}>
                  <span>
                    产品防伪验证二维码：
                  </span>
                  <Button className='finish' onClick={finish} style={{ fontSize: itemsfonts, height: miantitheight, display: 'flex', alignItems: 'center' }}><i className="animation"></i>生成二维码<i className="animation"></i></Button>

                  {/* {
                         url != '' ?
                           <Image src={url} style={{ marginLeft: imgmarl }} width={ewmwidth} preview={false} />
                           :
                           <Button className='finish' onClick={finish} style={{ fontSize: itemsfonts, height: miantitheight, display: 'flex', alignItems: 'center' }}><i className="animation"></i>生成二维码<i className="animation"></i></Button>
                       } */}
                </div>
                <div className='inputs' style={{ marginBottom: itemsitemsbot }}>
                  <span>
                    质量检验章
                  </span>
                  {
                    corporateName == '安徽富控' ?
                      <Image preview={true} src='https://444.shulan-tech.com/data/elevator/anhui.png' style={{ marginTop: itemsitemsbot }} width={imgmarl} />
                      :
                      <Image preview={true} src='https://444.shulan-tech.com/data/elevator/jiangxi.png' style={{ marginTop: itemsitemsbot }} width={imgmarl} />
                  }
                </div>
              </Col>
              <Col span={15} className='itemsright' style={{ paddingTop: itemsrightpadto, fontSize: itemsfonts }}>
                <Row justify='start'>
                  <Col span={22} >
                    <div className='miantit' style={{ height: miantitheight, fontSize: itemsfont }}>
                      <span style={{}}>主要技术参数</span>
                    </div>
                    <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                      <div style={{ width: miantitwidth, textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                        <span>生产号令</span>
                      </div>
                      <Input className='inputs' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ maxWidth: mianinputwidth, height: inputheight, fontSize: mianfontsizes }} placeholder="" />
                      <div style={{ width: miantitwidth, textAlign: 'center', lineHeight: miantitheight, borderLeft: `${setpp1} solid #000`, borderRight: `${setpp1} solid #000` }}>
                        <span>额定载重/kg</span>
                      </div>
                      <InputNumber className='inputs' type='num' disabled={disabled} value={weight} onChange={(e) => inputChanges(e, 'weight')} style={{ width: mianinputwidth, height: inputheight, fontSize: mianfontsizes }} placeholder="" />
                      <div style={{ width: miantitwidth, textAlign: 'center', lineHeight: miantitheight, borderLeft: `${setpp1} solid #000`, borderRight: `${setpp1} solid #000` }}>
                        <span>额定速度m/s</span>
                      </div>
                      <Input className='inputs' disabled={disabled} value={speed} onChange={(e) => inputChange(e, 'speed')} style={{ maxWidth: mianinputwidthend, height: inputheight, fontSize: mianfontsizes }} placeholder="" />
                    </div>
                    <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                      <div style={{ width: miantitwidth, textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                        <span>电梯层站</span>
                      </div>
                      <Input className='inputs' disabled={disabled} value={layer} onChange={(e) => inputChange(e, 'layer')} style={{ maxWidth: mianinputwidth, height: inputheight, fontSize: mianfontsizes }} placeholder="" />
                      <div style={{ width: miantitwidth, textAlign: 'center', lineHeight: miantitheight, borderLeft: `${setpp1} solid #000`, borderRight: `${setpp1} solid #000` }}>
                        <span>开门宽度/mm</span>
                      </div>
                      <InputNumber className='inputs' disabled={disabled} value={openingWidth} onChange={(e) => inputChanges(e, 'openingWidth')} style={{ width: mianinputwidth, height: inputheight, fontSize: mianfontsizes }} placeholder="" />
                      <div style={{ width: miantitwidth, textAlign: 'center', lineHeight: miantitheight, borderLeft: `${setpp1} solid #000`, borderRight: `${setpp1} solid #000` }}>
                        <span>开门方式</span>
                      </div>
                      <Input className='inputs' disabled={disabled} value={openMode} onChange={(e) => inputChange(e, 'openMode')} style={{ maxWidth: mianinputwidthend, height: inputheight, fontSize: mianfontsizes }} placeholder="" />
                    </div>
                    <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                      <div style={{ width: miantitwidth, textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                        <span>控制方式</span>
                      </div>
                      <Input className='inputs' disabled={disabled} value={controlMode} onChange={(e) => inputChange(e, 'controlMode')} style={{ maxWidth: mianinputwidth, height: inputheight, fontSize: mianfontsizes }} placeholder="" />
                      <div style={{ width: miantitwidth, textAlign: 'center', lineHeight: miantitheight, borderLeft: `${setpp1} solid #000`, borderRight: `${setpp1} solid #000` }}>
                        <span>开门高度/mm</span>
                      </div>
                      <InputNumber className='inputs' disabled={disabled} value={openingHeight} onChange={(e) => inputChanges(e, 'openingHeight')} style={{ width: mianinputwidth, height: inputheight, fontSize: mianfontsizes }} placeholder="" />
                      <div style={{ width: miantitwidth, textAlign: 'center', lineHeight: miantitheight, borderLeft: `${setpp1} solid #000`, borderRight: `${setpp1} solid #000` }}>
                        <span>轿厢尺寸/mm</span>
                      </div>
                      <Input className='inputs' disabled={disabled} value={carSize} onChange={(e) => inputChange(e, 'carSize')} style={{ maxWidth: mianinputwidthend, height: inputheight, fontSize: mianfontsizes }} placeholder="" />
                    </div>
                    {/* <Table style={{}} bordered dataSource={dataSource} columns={columns} pagination={false} showHeader={false} /> */}
                    <div className='miantits' style={{ height: miantitheight, fontSize: itemsfont, borderTop: `${setpp1} solid #000` }}>
                      <span style={{}}>主要部件</span>
                    </div>
                    <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                      <div style={{ width: '33.3%', textAlign: 'center', lineHeight: setpp50, borderRight: `${setpp1} solid #000` }}>
                        <span>部件名称</span>
                      </div>
                      <div style={{ width: '33.3%', textAlign: 'center', lineHeight: setpp50, borderRight: `${setpp1} solid #000` }}>
                        <span>部件型号</span>
                      </div>
                      <div style={{ width: '33.4%', textAlign: 'center', lineHeight: setpp50 }}>
                        <span>部件产品编号</span>
                      </div>
                    </div>
                    <div>
                      <MainComponent
                        data={datanew}
                        setpp1={setpp1}
                        mianfontsizes={mianfontsizes}
                        miantitheight={inputheight}
                        inputChange={inputChangenew}
                        disabled={false}
                      />
                      {/* <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                        <div style={{ width: '33.3%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <span>曳引机</span>
                        </div>
                        <div style={{ width: '33.3%', height: '100%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <Input className='inputss' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                        <div style={{ width: '33.4%', height: '100%', textAlign: 'center', lineHeight: miantitheight }}>
                          <Input className='inputs' disabled={disabled} value={speed} onChange={(e) => inputChange(e, 'speed')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                      </div>
                      <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                        <div style={{ width: '33.3%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <span>制动器</span>
                        </div>
                        <div style={{ width: '33.3%', height: '100%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <Input className='inputss' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                        <div style={{ width: '33.4%', height: '100%', textAlign: 'center', lineHeight: miantitheight }}>
                          <Input className='inputs' disabled={disabled} value={speed} onChange={(e) => inputChange(e, 'speed')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                      </div>
                      <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                        <div style={{ width: '33.3%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <span>上行超速保护装置</span>
                        </div>
                        <div style={{ width: '33.3%', height: '100%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <Input className='inputss' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                        <div style={{ width: '33.4%', height: '100%', textAlign: 'center', lineHeight: miantitheight }}>
                          <Input className='inputs' disabled={disabled} value={speed} onChange={(e) => inputChange(e, 'speed')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                      </div>
                      <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                        <div style={{ width: '33.3%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <span>控制柜</span>
                        </div>
                        <div style={{ width: '33.3%', height: '100%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <Input className='inputss' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                        <div style={{ width: '33.4%', height: '100%', textAlign: 'center', lineHeight: miantitheight }}>
                          <Input className='inputs' disabled={disabled} value={speed} onChange={(e) => inputChange(e, 'speed')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                      </div>
                      <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                        <div style={{ width: '33.3%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <span>限速器</span>
                        </div>
                        <div style={{ width: '33.3%', height: '100%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <Input className='inputss' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                        <div style={{ width: '33.4%', height: '100%', textAlign: 'center', lineHeight: miantitheight }}>
                          <Input className='inputs' disabled={disabled} value={speed} onChange={(e) => inputChange(e, 'speed')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                      </div>
                      <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                        <div style={{ width: '33.3%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <span>缓冲器</span>
                        </div>
                        <div style={{ width: '33.3%', height: '100%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <Input className='inputss' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                        <div style={{ width: '33.4%', height: '100%', textAlign: 'center', lineHeight: miantitheight }}>
                          <Input className='inputs' disabled={disabled} value={speed} onChange={(e) => inputChange(e, 'speed')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                      </div>
                      <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                        <div style={{ width: '33.3%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <span>安全钳</span>
                        </div>
                        <div style={{ width: '33.3%', height: '100%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <Input className='inputss' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                        <div style={{ width: '33.4%', height: '100%', textAlign: 'center', lineHeight: miantitheight }}>
                          <Input className='inputs' disabled={disabled} value={speed} onChange={(e) => inputChange(e, 'speed')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                      </div>
                      <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                        <div style={{ width: '33.3%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <span>层门</span>
                        </div>
                        <div style={{ width: '33.3%', height: '100%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <Input className='inputss' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                        <div style={{ width: '33.4%', height: '100%', textAlign: 'center', lineHeight: miantitheight }}>
                          <Input className='inputs' disabled={disabled} value={speed} onChange={(e) => inputChange(e, 'speed')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                      </div>
                      <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                        <div style={{ width: '33.3%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <span>层门锁</span>
                        </div>
                        <div style={{ width: '33.3%', height: '100%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <Input className='inputss' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                        <div style={{ width: '33.4%', height: '100%', textAlign: 'center', lineHeight: miantitheight }}>
                          <Input className='inputs' disabled={disabled} value={speed} onChange={(e) => inputChange(e, 'speed')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                      </div>
                      <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                        <div style={{ width: '33.3%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <span>轿门锁</span>
                        </div>
                        <div style={{ width: '33.3%', height: '100%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <Input className='inputss' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                        <div style={{ width: '33.4%', height: '100%', textAlign: 'center', lineHeight: miantitheight }}>
                          <Input className='inputs' disabled={disabled} value={speed} onChange={(e) => inputChange(e, 'speed')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                      </div>
                      <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                        <div style={{ width: '33.3%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <span>含有电子元件的安全回路</span>
                        </div>
                        <div style={{ width: '33.3%', height: '100%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <Input className='inputss' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                        <div style={{ width: '33.4%', height: '100%', textAlign: 'center', lineHeight: miantitheight }}>
                          <Input className='inputs' disabled={disabled} value={speed} onChange={(e) => inputChange(e, 'speed')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                      </div>
                      <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                        <div style={{ width: '33.3%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <span>生产号令</span>
                        </div>
                        <div style={{ width: '33.3%', height: '100%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <Input className='inputss' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                        <div style={{ width: '33.4%', height: '100%', textAlign: 'center', lineHeight: miantitheight }}>
                          <Input className='inputs' disabled={disabled} value={speed} onChange={(e) => inputChange(e, 'speed')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                      </div>
                      <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                        <div style={{ width: '33.3%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <span>停电应急平层</span>
                        </div>
                        <div style={{ width: '33.3%', height: '100%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <Input className='inputss' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                        <div style={{ width: '33.4%', height: '100%', textAlign: 'center', lineHeight: miantitheight }}>
                          <Input className='inputs' disabled={disabled} value={speed} onChange={(e) => inputChange(e, 'speed')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                      </div>
                      <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes }}>
                        <div style={{ width: '33.3%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <span>绳头组合</span>
                        </div>
                        <div style={{ width: '33.3%', height: '100%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <Input className='inputss' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                        <div style={{ width: '33.4%', height: '100%', textAlign: 'center', lineHeight: miantitheight }}>
                          <Input className='inputs' disabled={disabled} value={speed} onChange={(e) => inputChange(e, 'speed')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                      </div>
                      <div className='miantitss' style={{ height: miantitheight, fontSize: mianfontsizes, borderBottom: `${setpp1} solid #000` }}>
                        <div style={{ width: '33.3%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <span>悬挂装置</span>
                        </div>
                        <div style={{ width: '33.3%', height: '100%', textAlign: 'center', lineHeight: miantitheight, borderRight: `${setpp1} solid #000` }}>
                          <Input className='inputss' disabled={disabled} value={factoryNumber} onChange={(e) => inputChange(e, 'factoryNumber')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                        <div style={{ width: '33.4%', height: '100%', textAlign: 'center', lineHeight: miantitheight }}>
                          <Input className='inputs' disabled={disabled} value={speed} onChange={(e) => inputChange(e, 'speed')} style={{ width: '100%', height: '100%', fontSize: mianfontsizes }} placeholder="" />
                        </div>
                      </div> */}
                    </div>





                  </Col>
                  {/* <Col span={22} style={{ display: 'flex', fontSize: setpp24, borderBottom: `${setpp1} solid #000`, padding: `${setpp30} ${mianinputpadding}`, backgroundColor: '#FFFFFF', borderTop: `${setpp1} solid #000`, borderLeft: `${setpp1} solid #000`, borderRight: `${setpp1} solid #000` }}>
                     <div className='inputmain' >
                       <div style={{ width: setpp310 }}>
                         <span>
                           特种设备制造许可证编号：
                         </span>
                       </div>
                       <Input className='inputs' disabled={disabled} value={licenseNumber} onChange={(e) => inputChange(e, 'licenseNumber')} style={{ maxWidth: inputwidth, borderBottom: `${setpp1} solid #000`, height: inputheight, fontSize: mianfontsize }} placeholder="" />
                     </div>
                     <div className='inputmain' >
                       <div style={{ minWidth: '30px' }}>
                         <span>
                           有效日期至：
                         </span>
                       </div>
                       <Input className='inputs' disabled={disabled} value={effectiveDate} onChange={(e) => inputChange(e, 'effectiveDate')} style={{ maxWidth: inputwidth, borderBottom: `${setpp1} solid #000`, height: inputheight, fontSize: mianfontsize }} placeholder="" />
                     </div>
                   </Col>
                   <Col span={22} style={{ borderBottom: `${setpp1} solid #000`, fontSize: mianfontsize, padding: `${setpp30} ${mianinputpadding}`, backgroundColor: '#FFFFFF', borderLeft: `${setpp1} solid #000`, borderRight: `${setpp1} solid #000` }}>
                     <div className='inputmain'>
                       <span>
                         生产设备代码：
                       </span>
                       <Input className='inputs' disabled={disabled} value={equipmentCode} onChange={(e) => inputChange(e, 'equipmentCode')} style={{ maxWidth: inputwidth, borderBottom: `${setpp1} solid #000`, height: inputheight, fontSize: mianfontsize }} placeholder="" />
                     </div>
                   </Col>
                   <Col span={22} style={{ borderBottom: `${setpp1} solid #000`, fontSize: mianfontsize, padding: `${mianinputpaddings} ${mianinputpadding}`, height: itemsleftpadle, backgroundColor: '#FFFFFF', borderLeft: `${setpp1} solid #000`, borderRight: `${setpp1} solid #000` }}>
                     <div className='inputmain' style={{ alignItems: 'start' }}>
                       <span>
                         产品防伪验证二维码：
                       </span>
                       {
                         url != '' ?
                           <Image src={url} style={{ marginLeft: imgmarl }} width={ewmwidth} preview={false} />
                           :
                           <Button className='finish' onClick={finish} style={{ fontSize: itemsfonts, height: miantitheight, display: 'flex', alignItems: 'center' }}><i className="animation"></i>生成二维码<i className="animation"></i></Button>
                       }
                     </div>
                   </Col>
                   <Col span={22} style={{ fontSize: mianfontsize, padding: `${mianinputpaddings} ${mianinputpadding}`, height: ewmmainhei, backgroundColor: '#FFFFFF', borderLeft: `${setpp1} solid #000`, borderRight: `${setpp1} solid #000`, borderBottom: `${setpp1} solid #000` }}>
                     <div className='answerfj'>
                       <span>
                         产品质量保证声明：
                       </span>
                       <div style={{ lineHeight: itemsfont, fontSize: mianfontsizes, marginTop: mianfontsize, marginBottom: mianfontsize, height: itemsleftpadri }}>
                         <span style={{ fontWeight: 400 }}>
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为了保护广大客户合法权益，客户可通过手机扫描产品防伪验证二维码，经富士电梯防伪系统（官方域名www.fujielevatorcn.com）验证产品出厂信息。我司对所有假冒富士电梯或Fuji Elevator 品牌的产品概不负责。
                         </span>
                       </div>
                       <div style={{ float: 'right' }}>
                         <span style={{ fontWeight: 400 }}>
                           富控电梯有限公司
                         </span>
                       </div>
                     </div>
                   </Col> */}
                  {/* <Col span={22} style={{ display: 'flex', fontSize: setpp24, borderBottom: `${setpp1} solid #000`, padding: `${setpp30} ${mianinputpadding}`, backgroundColor: '#FFFFFF', borderTop: `${setpp1} solid #000`, borderLeft: `${setpp1} solid #000`, borderRight: `${setpp1} solid #000` }}>
                    <Form form={form} component={false}>

                      <Table
                        style={{ width: "100%", fontSize:'18px' }}
                        size='small'
                        components={components}
                        rowClassName={() => 'editable-row'}
                        bordered
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                      />
                    </Form>
                  </Col> */}
                </Row>
              </Col>
            </Row>

          </div>
        </Col>
      </Row>
    </div>
  );
};
export default About;
