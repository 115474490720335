import { useNavigate } from 'react-router-dom';
import './index.scss'
import React from 'react';
import { Col, Layout, Image, Row } from 'antd';
import car01 from '../../assets/images/car01.webp';
import car02 from '../../assets/images/car02.webp';
import car03 from '../../assets/images/car03.webp';
import car04 from '../../assets/images/car04.webp';
import Escalator01 from '../../assets/images/Escalator01.webp';
import Escalator02 from '../../assets/images/Escalator02.webp';
import Escalator03 from '../../assets/images/Escalator03.webp';
import Escalator04 from '../../assets/images/Escalator04.webp';
import Escalator05 from '../../assets/images/Escalator05.webp';
import Escalator06 from '../../assets/images/Escalator06.webp';
import Escalator07 from '../../assets/images/Escalator07.webp';
import Escalator08 from '../../assets/images/Escalator08.webp';
const { Header, Content, Footer } = Layout;
class Escalator extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Escalator & Moving walk',
      header_title: 'Escalator & Moving walk',
      header_content: 'New generation FUJI escalator / moving walkway takes the FUJI’s design style with safety, reliability and high-efficiency, which is developed to meet the market requirements. The escalator is widely used in many places such as supermarket, shopping center, train station, airport, CBD and office building etc.',

      top1_titel: '(1) Product  Features',
      top1_content: [
        'New design of streamline handrail inlet shows modern style under the premise of safety, and is applicable to all types of handrail passenger conveyors.',
        '- Advanced intelligent system design and quality test make elements have long service life and durable in use.',
        '- Use flexible roller to minimize energy consumption during running.',
        '- Use multiple safety devices to ensure safety and stable running.',
        '- Compact structure, light weight, and space-saving.'
      ],

      top2_titel: '(2) Four series',
      top1_tabs: {
        title: ['- Light escalator series: ', '- Heavy escalator series:', 'Semi-outdoor escalator series:', '- Moving walkway series:'],
        content: [
          "Fashionable, elegant and practical, are especially designed for shopping centers.",
          "Perfect experience for big passenger flow, to meet the needs of city public transportation.",
          "No fear of rain, snow and frost.  High protection class configurations make it be widely used in subway system, train station,outdoor commercial plaza etc buildings.",
          "passing happiness and warmness in city. It's widely used in airport, station, subway system, exhibition center, shopping centers, with fashion appearance design, stable& quiet operation, long life service."
        ]
      },

      top4_titel: '(4) Part Construction parameters',
      top4_tabs: [
        {
          title: '(A). 30° Escalator (indoor)',
          Note: ['– Above drawing is applicable for construction of single unit of escalator installation no more than 6m.',
            '– The stair width is 600mm and upper part of truss should be extended by 417mm.'
          ],
          img: [Escalator05, Escalator06]

        },
        {
          title: '(A). 30° Escalator (indoor)',
          Note: ['– Above drawing is applicable for construction of single unit of escalator installation no more than 6m.',
            '– The stair width is 600mm and upper part of truss should be extended by 417mm.'
          ],
          img: [Escalator07, Escalator08]
        }
      ]

    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { code } = this.state;
    console.log(this.state);
    return (
      <Layout style={{ background: '#FFFFFF' }}>

        <Content className="site-layout" style={{ padding: '1rem' }}>
          <div>
            <h2>{this.state.header_title}</h2>
            <p>{this.state.header_content}</p>
            <div style={{ width: '70%', padding: '1rem 0 0 0' }} ><img src={car01} /></div>


          </div>

          <div>
            <h3>{this.state.top1_titel}</h3>
            {
              this.state.top1_content.map((item, indexs) => {
                return (<p key={indexs}>{item}</p>)
              })
            }
          </div>

          <div>
            <h3>{this.state.top2_titel}</h3>
            <Row justify='center'>
              <Col span={24}>
                <Row justify='center'>
                  <Col span={12} >
                    <h4 style={{ display: 'inline' }}>{this.state.top1_tabs.title[0]}</h4>
                    <span style={{ textAlign: 'left' }}>{this.state.top1_tabs.content[0]}</span>
                  </Col>
                  <Col span={12}>
                    <h4 style={{ display: 'inline' }}>{this.state.top1_tabs.title[1]}</h4>
                    <span style={{ textAlign: 'left' }}>{this.state.top1_tabs.content[1]}</span>
                  </Col>
                </Row>
                <img src={Escalator02} />

              </Col>
              <Col span={24}>
                <Row justify='center'>
                  <Col span={12} >
                    <h4 style={{ display: 'inline' }}>{this.state.top1_tabs.title[2]}</h4>
                    <span style={{ textAlign: 'left' }}>{this.state.top1_tabs.content[2]}</span>
                  </Col>
                  <Col span={12}>
                    <h4 style={{ display: 'inline' }}>{this.state.top1_tabs.title[3]}</h4>
                    <span style={{ textAlign: 'left' }}>{this.state.top1_tabs.content[3]}</span>
                  </Col>
                </Row>
                <img src={Escalator03} />
              </Col>
            </Row>
          </div>
          <div>
            <img src={Escalator04} />
          </div>
          <div>
            <h3>{this.state.top2_titel}</h3>
            {
              this.state.top4_tabs.map((item, indexs) => {
                return (<div key={indexs}>
                  <h4>{item.title}</h4>
                  <img src={item.img[0]} />
                  <p>{item.Note[0]}</p>
                  <p>{item.Note[1]}</p>
                  <img src={item.img[1]} />
                </div>)
              })
            }
          </div>
        </Content>
      </Layout>
    )
  }
}


export default Escalator;
