import { useNavigate,Outlet } from 'react-router-dom';

import React from 'react';
import Headertop from '../../components/Headertop';
import { Carousel, Layout, Image, theme } from 'antd';
import lift01 from '../../assets/images/080103.jpg'
import lift02 from '../../assets/images/080104.jpg'
import Nextroute from '../../components/Nextroute';
const { Header, Content, Footer } = Layout;
class Investor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title:'Project',
      routeitems: [],
      keyRout:''
    }
  }

  getRouteitems=(e)=>{
    this.setState({
      routeitems: e
    })
  }
  getKeyrout=(e)=>{
    this.setState({
      keyRout: e
    })
  }
  render() {
    const { code } = this.state;

    return (

      <div>
      <Image preview={false} src={lift01} style={{width:'100vw',display:'block'}} />
      <Image preview={false} src={lift02} style={{width:'100vw'}} />
    </div>
        //  <Outlet/>
    )
  }
}
export default Investor;
