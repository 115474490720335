// import React from 'react';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { AppstoreOutlined, TeamOutlined, PhoneOutlined } from '@ant-design/icons';

import React from 'react';
import { Image, Layout, Row, Col, Card } from 'antd';
import './index.scss';
import qualification01 from '../../assets/images/qualification01.webp';
import qualification02 from '../../assets/images/qualification02.webp';
import qualification03 from '../../assets/images/qualification03.webp';
import qualification04 from '../../assets/images/qualification04.webp';
import qualification05 from '../../assets/images/qualification05.webp';
import qualification06 from '../../assets/images/qualification06.webp';
import qualification07 from '../../assets/images/qualification07.webp';
import qualification08 from '../../assets/images/qualification08.webp';
import qualification09 from '../../assets/images/qualification09.webp';
import qualification10 from '../../assets/images/qualification10.webp';
const qualificationImages = [
  {img:qualification01,text:'Logo registration in Japan'},
  {img:qualification02,text:'Authorization certificate'},
  {img:qualification03,text:'Business registration'},
  {img:qualification04,text:'Manufacture license(Zhejiang factory)'},
  {img:qualification05,text:'Manufacture license(Anhui factory)'},
  {img:qualification06,text:'ISO9001 certificate'},
  {img:qualification07,text:'ISO14001 certificate'},
  {img:qualification08,text:'OHSAS18001 certificate'},
  {img:qualification09,text:'CE certificate'},
  {img:qualification10,text:'CE certificate'},

  
]


const { Header, Content, Footer } = Layout;
class Culture extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Qualification & Certificate',
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { code } = this.state;

    return (
      <Layout>

        <Content className="site-layout" style={{backgroundColor:'#FFFFFF'}}>
          <section style={{ marginTop: '2rem' }}>
            <h2>我们的服务</h2>
            <Row gutter={[16, 16]}>
              {
                qualificationImages.map((item,indexs)=>{
                  return (<Col style={{width:'20%'}} key={indexs}>
                    <Card
                      hoverable
                      cover={<Image alt="example" src={item.img} />}
                    >
                      <Card.Meta title="" description={item.text} />
                    </Card>
                  </Col>)
                })
              }
              
            </Row>
          </section>
        </Content>
        <Footer style={{ backgroundColor: '#FFFFFF', padding: '24px 0' }}>
        </Footer>
      </Layout>
    )
  }
}


export default Culture;
