import './index.scss';
import { useNavigate } from 'react-router-dom';
import { NavLink as Link } from 'react-router-dom';
import React from 'react';
import { Row,Col, Layout, Menu, Image } from 'antd';
import routes from '../../routes/index';
import logo from '../../assets/images/elevator_logo.png'
const { Header } = Layout;


export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};


class Headertop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      code: [
        {title:'首页',key:'/home'},
         {title:'关于我们',key:'/about'}, 
         {title:'新闻中心',key:'/news'}, 
         {title:'业务组合',key:'/products'}, 
         {title:'投资者关系',key:'/investor'}, 
         {title:'加入我们',key:'/join'}, 
      ],
      selectId: '2',
      routeItems:[],
      items: [{
        key: "/",
        label: <Link to="/home">home</Link>
      }, {
        key: "/products",
        label: <Link to="/products">Products</Link>,
        children: [{
          key: "/products/passenger_elevator",
          label: <Link to="/products/passenger_elevator">Passenger Elevator</Link>
        }, {
          key: "/products/observation_elevator",
          label: <Link to="/products/observation_elevator">Observation Elevator</Link>
        }, {
          key: "/products/freight_elevator",
          label: <Link to="/products/freight_elevator">Freight Elevator</Link>
        }, {
          key: "/products/hospital_elevator",
          label: <Link to="/products/hospital_elevator">Hospial Elevator</Link>
        }, {
          key: "/products/home_elevator",
          label: <Link to="/products/home_elevator">Home Elevator</Link>
        },{
          key: "/products/car_elevator",
          label: <Link to="/products/car_elevator">Car Elevator</Link>
        }, {
          key: "/products/hydraulic_elevator",
          label: <Link to="/products/hydraulic_elevator">Hydraulic Elevators</Link>
        }, {
          key: "/products/escalator_movingWalk",
          label: <Link to="/products/escalator_movingWalk">Escalator & Moving Walk</Link>
        }, {
          key: "/products/dumbwaiter",
          label: <Link to="/products/dumbwaiter">Dumbwaiter</Link>
        }, {
          key: "/products/stereo_parking_garages",
          label: <Link to="/products/stereo_parking_garages">Stereo parking garages</Link>
        }]
      }, {
        key: "/about",
        label: <Link to="/about">About Us</Link>,
        children: [{
          key: "/about/profile",
          label: <Link to="/about/profile">Company Profile</Link>
        }, {
          key: "/about/organization_chart",
          label: <Link to="/about/organization_chart">Organization Chart</Link>
        }, {
          key: "/about/history",
          label: <Link to="/about/history">History</Link>
        }, {
          key: "/about/qualification_certificate",
          label: <Link to="/about/qualification_certificate">Qualification & Certificate</Link>
        },
        //  {
        //   key: "/about/qualification",
        //   label: <Link to="/about/qualification">资质荣誉</Link>
        // }, {
        //   key: "/about/responsibility",
        //   label: <Link to="/about/responsibility">社会责任</Link>
        // }, {
        //   key: "/about/connect",
        //   label: <Link to="/about/connect">联系我们</Link>
        // }
      ]
      },
      //  {
      //   key: "/news",
      //   label: <Link to="/news">News</Link>,
      //   children: [{
      //     key: "/news/newsone",
      //     label: <Link to="/news/newsone">成员管理</Link>
      //   }
      //   , {
      //     key: "/news/indexs",
      //     label: <Link to="/news/indexs">权限设置</Link>
      //   }
      // ]
      // },
      //  {
      //   key: "/project",
      //   label: <Link to="/project">Project</Link>,
      //   children: [{
       
      //     key: "/project/stock",
      //     label: <Link to="/project/stock">number1</Link>
      //   }, {
      //     key: "/project/exchange",
      //     label: <Link to="/project/exchange">number2</Link>
      //   }, {
      //     key: "/project/email",
      //     label: <Link to="/project/email">number3</Link>
      //   }]
      // },
      // {
      //   key: "/fuji",
      //   label: <Link to="/fuji">许可证</Link>,
        
      // }
      // ,{
      //   key: "/hold",
      //   label: <Link to="/hold">hold</Link>,
       
      // }
      ]
    }
  }

  componentDidMount() {
    const {items} = this.state
    console.log(items);
    items.forEach(item => {
      if (item.label.props.children == this.props.name) {
        if (item.children) {
          this.props.getKeyrout(item.children[0].key)
        }else{
          this.props.getKeyrout(item.key)
        }
        this.props.getRouteitems(item)
        
      }
    })
  }

  componentWillReceiveProps(nextProps) {
  }
  onClick=(e)=>{
  }
  changeRoute = (e) => {
    const routItem = e.keyPath[e.keyPath.length-1];
    let routeItems = [];
    const {items} = this.state
    items.forEach(item => {
      if (item.key == routItem) {
        routeItems.push(item)
      }
    })
    this.props.getKeyrout(e.key)
    this.props.getRouteitems(routeItems[0])
    this.setState({
      selectId: e.key,
      routeItems
    })
  }
  render() {
    const { code, items, selectId } = this.state;
    return (
      <Header className='header' style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
        {/* <Row justify="center">
            <Col span={20}> */}
        <div className='headermid'>
          <div
            className='logobox'
            style={{
              float: 'left',
              width: 226,
              height: 84,
            }}>
            <Image src={logo} preview={false}/>
          </div>
          <Menu
            className='menutitle'
            theme="light"
            mode="horizontal"
            // selectedKeys={['/about']}
            onSelect={this.changeRoute}
            items={items}
          />
        </div>
        {/* </Col>
            </Row> */}
      </Header>
    )
  }
}


export default withNavigation(Headertop);
