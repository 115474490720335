import { useNavigate } from 'react-router-dom';
import './index.scss'
import React from 'react';
import { Col, Layout, Image, Row } from 'antd';
import elevator02 from '../../assets/images/type_elevator02.jpg';

import home01 from '../../assets/images/home01.webp';
import Hydraulic01 from '../../assets/images/Hydraulic01.webp';
import Hydraulic02 from '../../assets/images/Hydraulic02.webp';
const { Header, Content, Footer } = Layout;
class Dumbwaiter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Dumbwaiter',
      header_title: 'Dumbwaiter',
      header_content: 'Fuji hydraulic elevator has the features of flexible machine room location, large load capacity, low noise, stable operation and easy maintenance. Because it’s no need to set the machine room at top floor, the effective shaft space is comparatively large, and the shaft top is under comparatively light presure, so the building structure could be simply arranged, which can reduce the building construction cost. Therefore, FUJI hydraulic elevator is suitable to use in medium and small buildings.',

      top1_titel: 'Key Features:',
      top1_tabs:
      {
        imgs: [{ img: Hydraulic01, text: 'Motor and pump' }, { img: Hydraulic02, text: 'Control valve' }],
        texts: [
          '► Machine room can be set up freely on any floor, giving customers more options.',
          '► It can be installed in relatively small shaft without counterweights, saving building space.            ',
          '► Compared with traction elevators,  it has fewer parts, so it makes it easier to install and maintain.',
          '► Large load capacity.',
          '►High performance driving motor and pump, which makes the elevator operate stably and quietly.'
        ],
      },

    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { code } = this.state;
    return (
      <Layout style={{ background: '#FFFFFF' }}>

        <Content className="site-layout" style={{ padding: '1rem' }}>
          <div>
            <h2>{this.state.header_title}</h2>
            <p>{this.state.header_content}</p>
            <div className='ele-img'><img src={elevator02} /></div>
          </div>

          <div>
            <h4>{this.state.top1_titel}</h4>
            <div>
              {this.state.top1_tabs.texts.map((item, indexs) => {
                return (<p key={indexs}>{item}</p>)
              })}
                <Row style={{width:'60%'}}>
                  <Col span={24} style={{ display: 'flex', justifyContent: 'space-around'}}>
                  <img style={{width:'30%'}} src={Hydraulic01}/>
                  <img style={{width:'30%'}} src={Hydraulic02}/>
                   </Col>
                  <Col  span={24} style={{ display: 'flex', justifyContent: 'space-around'}}>
                    <span>{this.state.top1_tabs.imgs[0].text}</span>
                    <span>{this.state.top1_tabs.imgs[1].text}</span>
                  </Col>
                </Row>
            </div>
            {/* {
              this.state.top1_tabs.map((item, indexs) => {
                return (<div className='to-top' key={indexs}>
                  <img src={item.img} />
                  <div className='img-text'>
                    {
                      item.texts.map((item, indexs) => {
                        return (<div key={indexs}><p style={{ textAlign: 'center' }}>{item.text0}</p><p style={{ textAlign: 'center' }}>{item.text1}</p></div>
                        )
                      })
                    }
                  </div>

                </div>)
              })
            } */}
          </div>

        </Content>
      </Layout>
    )
  }
}


export default Dumbwaiter;
