import './index.scss';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Tabs, Layout, Breadcrumb } from 'antd';
import routes from '../../routes/index';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
const { Header } = Layout;


export const withNavigation = (Component) => {
    return (props) => <Component {...props} navigate={useNavigate()} />;
};


class Nextroute extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            routeitems: [],
            routeTabs: [],
            keyRout: '',
            childrenRout: ''
        }

    }
    componentDidMount = () => {
    }
    componentWillReceiveProps = (nextProps) => {
        console.log(nextProps, 'next');
        let routeTabs = [];
        let childrenRouts = '';
        let { keyRout, childrenRout } = this.state
        if (nextProps) {
            if (nextProps.routeitems != '') {
                if (nextProps.routeitems.children) {
                    nextProps.routeitems.children.forEach(items => {
                        routeTabs.push({
                            label: items.label.props.children,
                            key: items.key
                        })
                        if (nextProps.keyRout == items.label.props.to && childrenRout == '') {
                            childrenRouts = items.label.props.children
                            this.setState({
                                childrenRout: childrenRouts
                            })
                        }
                    })
                }else{
                    routeTabs.push({
                        label: nextProps.routeitems.label.props.children,
                        key: nextProps.routeitems.key
                    })
                    this.setState({
                        childrenRout: nextProps.routeitems.label.props.children
                    })
                }
                if (nextProps.routeitems.label.props.children=='Products') {
                    routeTabs.unshift({label:nextProps.routeitems.label.props.children,key:nextProps.routeitems.label.props.to, istrue:true})
                }
            }
            this.setState({
                routeTabs,
                routeitems: nextProps.routeitems
            })

            if (keyRout == '') {
                this.setState({
                    keyRout: nextProps.keyRout
                })
            }

        }
    }
    changeTabs = (e) => {
        this.props.navigate(e)

    }
    onTabClick = (e) => {
        let { routeTabs } = this.state
        routeTabs.forEach((item) => {
            if (e == item.key) {
                    this.setState({
                        childrenRout: item.label,
                        keyRout: e
                    },()=>{
                        this.props.getChildren(item.label)
                    })


            }
        })

    }
    render() {
        const { routeitems, routeTabs, keyRout, childrenRout } = this.state;
        let crumbtitle = '';
        let crumbhref = '';
        if (routeitems != '') {
            crumbtitle = routeitems.label.props.children;
            crumbhref = routeitems.label.props.to
        }
        return (
            <div className='headers' style={{  width: '100%' }}>
                <div className='headermids'>
                    {/* <Breadcrumb
                        separator=">"
                        style={{ fontSize: '15px', lineHeight: '84px' }}
                        items={[
                            {
                                title: (
                                    <>
                                        <HomeOutlined />
                                        <span>{crumbtitle}</span>
                                    </>
                                ),
                                href: crumbhref
                            },
                            {
                                title: childrenRout,
                            }
                        ]}
                    /> */}
                    <Tabs
                        size='large'
                        tabPosition='left'
                        activeKey={keyRout}
                        // defaultActiveKey='3'
                        onChange={this.changeTabs}
                        items={routeTabs}
                        onTabClick={this.onTabClick}
                    />
                </div>
            </div>
        )
    }
}


export default withNavigation(Nextroute);
