import { useNavigate } from 'react-router-dom';
import './index.scss'
import React from 'react';
import { Col, Layout, Image, Row } from 'antd';
import elevator06 from '../../assets/images/type_elevator06.jpg';

import observation11 from '../../assets/images/observation11.webp';
import observation12 from '../../assets/images/observation12.webp';
import observation31 from '../../assets/images/observation31.webp';
import observation41 from '../../assets/images/observation41.webp';
import observation61 from '../../assets/images/observation61.png';
import observation71 from '../../assets/images/observation71.png';
import observation81 from '../../assets/images/observation81.webp';
import observation82 from '../../assets/images/observation82.webp';
import observation83 from '../../assets/images/observation83.webp';
import observation84 from '../../assets/images/observation84.webp';
import observation85 from '../../assets/images/observation85.webp';
import observation86 from '../../assets/images/observation86.webp';
import observation87 from '../../assets/images/observation87.webp';
import observation88 from '../../assets/images/observation88.webp';

import passenger211 from '../../assets/images/passenger211.webp';
import passenger212 from '../../assets/images/passenger212.webp';
import passenger221 from '../../assets/images/passenger221.webp';
import passenger222 from '../../assets/images/passenger222.webp';
import passenger23 from '../../assets/images/passenger23.webp';
import passenger51 from '../../assets/images/passenger51.webp';
import passenger61 from '../../assets/images/passenger61.webp';
const { Header, Content, Footer } = Layout;
class Observation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Observation Elevator',
      header_title: 'Observation elevator',
      header_content: ['FUJI observation elevator / sightseeing elevator / panoramic elevator / panoramic lift is being widely used in hotels, shopping malls and office buildings, where it carries the well-dressed passengers, flowing silently. FUJI Elevator adds to a beautiful landscape for the buildings owing to its humanized design and unique shape, and fully interprets the modern urban style.','FUJI observation elevator adopts new generation of intelligent VVV integrated controller, which has combined elevator /lift control and motor driving. This kind of new designed controller can save the whole elevator cost with less electrical components and less controller space. Moreover, it makes it easier for people to operate the controller and maintain the elevator.','FUJI observation elevator adopts advanced permanent synchronous gearless traction motor, which can improve efficiency by 30-50% to reduce the power consumption, compared with traditional system. Moreover, the advanced motor can work with low noise, no need lubricating oil, which makes it more environment friendly. 3-layer safety laminated glasses provide both good sightseeing vision and safety to passengers.'],
      
      top1_titel: '1. Car design',
      top1_content: "Fuji Elevator’s “human-oriented” concept combines with unique design and lovely ornament. FUJI Elevator studies the relations between vision, audition, touch sensation and human, and reaches harmony unification of use function and esthetic feeling in a relatively-closed space. All these are expressions of human natures, and thus meet the needs that human pursue for a comfortable and unique living environment. All these reflect that Fuji Elevator dedicates itself to looking for ways to show its respect for human natures.",
      top1_tabs: [
        {
          title: '(1). Round-shape design',
          imgs: [
            { img: observation11, texts: ['FJ-TGJ-010', 'FJ-TGJ-001', 'FJ-TGJ-003', 'FJ-TGJ-009', 'FJ-TGJ-004'] },
          ],
        },
        {
          title: '(2). Square-shape sightseeing design',
          imgs: [
            { img: observation12, texts: ['FJ-TGJ-007', 'FJ-TGJ-012', 'FJ-TGJ-006', 'FJ-TGJ-005'] },
          ],
        }
      ],
      top1_points: '- One direction / two directions / three directions / full four directions sightseeing can be customized according to demand. ',
      
      top2_titel: '2. Human-Machine Exchange System',
      top2_content: [
        'Optional car signal device (COP) and Hall signal device (LOP).',
        'Car operation panel(COP) buttons and landing call buttons are designed according to ergonomics theory, which have good feeling of hand touch. You just need touch the button softly and it will send control command. The display can indicate all numbers and symbols to be needed clearly.',
        'COP (Car Operating Panel) and LOP (Hall Buttons) material: Stainless steel or tempered glass. There are four types of stainless steel material COPs for choices: (a). Hairline, (b). Mirror finish (c). Titanium, (d). Mirror finish in both two sides and hairline in middle part. For tempered glass material COP, you can decide what color you want.',
      ],
      top2_tabs: [
        {
          title: '1).  Optional COP designs：',
          imgs: [
            { img: passenger211, texts: ['FJ-C11', 'FJ-C02', 'FJ-C21', 'FJ-C20', 'FJ-C01', 'FJ-C14', 'FJ-C08', 'FJ-C04', 'FJ-C06', 'FJ-C05', 'FJ-HC01'] },
            { img: passenger212, texts: ['FJ-C02', 'FJ-C11', 'FJ-C11', 'FJ-C22', 'FJ-C23', 'FJ-C14', 'FJ-C17', 'FJ-C17', 'FJ-C17', 'FJ-C03', 'FJ-C16'] }
          ],
        },
        {
          title: '2).  Optional Hall Buttons (LOP) designs:',
          imgs: [
            { img: passenger221, texts: ['FJ-Z11', 'FJ-Z02', 'FJ-Z21', 'FJ-Z20', 'FJ-Z04', 'FJ-Z06', 'FJ-Z12', 'FJ-Z01', 'FJ-C08', 'FJ-Z05'] },
            { img: passenger222, texts: ['FJ-Z14', 'FJ-Z02', 'FJ-Z23', 'FJ-Z17', 'FJ-Z17', 'FJ-Z03', 'FJ-Z17', 'FJ-Z17-2	', 'FJ-Z14-2	', 'FJ-Z23-2'] }
          ],
        },
        {
          title: '3). Optional display screen /indicator designs on COP and LOP :',
          imgs: [
            { img: passenger23, texts: ['Dot matrix display	', 'Blue/white LCD', '5.7" LED back light LCD', '7" or 9.7" TFT multi color LCD  display', '4.3"TFT'] },
          ],
        }
      ],
      top2_points: '► Display screen type : You can select one type from red dot-matrix display, blue/white color LCD display and TFT multi color LCD display.',
      

      top3_titel: '3. Optional Car Ceiling：',
      top3_tabs: {
        img: observation31, texts: ['FJ-D12', 'FJ-D14', 'FJ-D39', 'FJ-D40', 'FJ-D41', 'FJ-D42'],
      },

      top4_titel: '4. Optional Car Flooring - Marble-imitated PVC or real marble：',
      top4_tabs: {
        imgs: [
          { img: observation41, texts: ['FJ-JDG01', 'FJ-JDG02', 'FJ-JDG03', 'FJ-JDG04', 'FJ-JDG05'] },
          { img: passenger61, texts: ['FJ-JD01', 'FJ-JD02', 'FJ-JD05', 'FJ-JD06', 'FJ-JD07', 'FJ-JD09', 'FJ-JD19', 'FJ-JD18', 'FJ-JD08'] }
        ],
      },

      top5_titel: '5. Optional Car Door and Landing Door Design ( Standard narrow jamb, wide jamb could be chose).',
      top5_tabs: {
        img: passenger51,
        texts: [
          { text0: 'FJ-TM01', text1: 'Hairline ST / ST' },
          { text0: 'FJ-TM02', text1: 'Painted plate' },
          { text0: 'FJ-TM13', text1: 'Mirror / etching' },
          { text0: 'FJ-TM03', text1: 'Mirror / etching' },
          { text0: 'FJ-TM12', text1: 'Glass / hairline' },
          { text0: 'FJ-TM04', text1: 'Titanium / etching / mirror' },
          { text0: 'FJ-TM20', text1: 'Titanium / etching / mirror' },
        ],
      },
      
      top6_titel: '6. Standard Functions',

      top7_titel: '7. Optional Functions',

      top8_titel: '8. Hoistway Construction Layout  Drawing and  Technical Specifications:',
      top8_tabs: [
        {
          title: '(A). Small Machine Room (MR) Observation Elevator (Round-shape)', img: [observation81, observation82]
        },
        {
          title: '(B). Machine Room less (MRL) Observation Elevator (Round-shape)', img: [observation83, observation84]
        },
        {
          title: '(C). Machine Room Passenger Elevator (Square-shape)', img: [observation85, observation86]
        },
        {
          title: '(D). Machine Room less Passenger Elevator (Square-shape)', img: [observation87, observation88]
        },
      ],
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { code } = this.state;
    console.log(this.state);
    return (
      <Layout style={{ background: '#FFFFFF' }}>

        <Content className="site-layout" style={{ padding: '1rem' }}>
          <div>
            <h2>{this.state.header_title}</h2>
            <p>{this.state.header_content[0]}</p><br/>
            <p>{this.state.header_content[1]}</p>
            <br/>
            <p>{this.state.header_content[2]}</p>
            <div className='ele-img'><img src={elevator06} /></div>

          </div>

          <div>
            <h4>{this.state.top1_titel}</h4>
            {/* <p>{this.state.top2_content}</p> */}
            <p>{this.state.top1_content}</p>
            {
              this.state.top1_tabs.map((item, indexs) => {
                return (<div key={indexs}>
                  <h4>{item.title}</h4>
                  {
                    item.imgs.map((val, element) => {
                      return (<div key={element} style={{ paddingBottom: '1rem' }}>
                        <img src={val.img} />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          {val.texts.map((e, num) => {
                            return (<p style={{ textAlign: 'center' }} key={num}>{e}</p>)
                          })}

                        </div>
                      </div>)
                    })
                  }

                </div>)
              })
            }
            <p>{this.state.top1_points}</p>
          </div>

          <div>
            <h4>{this.state.top2_titel}</h4>
            {/* <p>{this.state.top2_content}</p> */}
            <div>
              {
                this.state.top2_content.map((val, element) => {
                  return <p key={element}>{val}</p>
                })
              }
            </div>
            {
              this.state.top2_tabs.map((item, indexs) => {
                return (<div key={indexs}>
                  <h4>{item.title}</h4>
                  {
                    item.imgs.map((val, element) => {
                      return (<div key={element} style={{ paddingBottom: '1rem' }}>
                        <img src={val.img} />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          {val.texts.map((e, num) => {
                            return (<p style={{ textAlign: 'center' }} key={num}>{e}</p>)
                          })}

                        </div>
                      </div>)
                    })
                  }

                </div>)
              })
            }
            <p>{this.state.top2_points}</p>
          </div>

          <div>
            <h4>{this.state.top3_titel}</h4>
            <div>
              <img src={this.state.top3_tabs.img} />
              <div className='img-text'>
                {
                  this.state.top3_tabs.texts.map((item, indexs) => {
                    return (
                      <p key={indexs}>{item}</p>)
                  })
                }
              </div>
            </div>
          </div>

          <div>
            <h4>{this.state.top4_titel}</h4>
            {
                    this.state.top4_tabs.imgs.map((val, element) => {
                      return (<div key={element} style={{ paddingBottom: '1rem' }}>
                        <img src={val.img} />
                        <div className='img-text'>
                          {val.texts.map((e, num) => {
                            return (<p style={{ textAlign: 'center' }} key={num}>{e}</p>)
                          })}

                        </div>
                      </div>)
                    })
                  }
          </div>

          <div>
            <h4>{this.state.top5_titel}</h4>
            <div>
              <img src={this.state.top5_tabs.img} />
              <div className='img-text'>
                {
                  this.state.top5_tabs.texts.map((item, indexs) => {
                    return (<div key={indexs}><p style={{ textAlign: 'center' }}>{item.text0}</p><p style={{ textAlign: 'center' }}>{item.text1}</p></div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          
          <div>
            <h4>{this.state.top6_titel}</h4>
            <div><img src={observation61}/></div>
          </div>
          <div>
            <h4>{this.state.top7_titel}</h4>
              <div><img src={observation71} /></div>
          </div>
          <div>
            <h4>{this.state.top8_titel}</h4>
            {this.state.top8_tabs.map((item,indexs)=>{
              return(<div className='to-top' key={indexs}>
              <p>{item.title}</p>
              <div><img src={item.img[0]} /></div>
              <div><img src={item.img[1]} /></div>
            </div>
              )
            })}
            
          </div>
        </Content>
      </Layout>
    )
  }
}


export default Observation;
