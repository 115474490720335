import { useNavigate } from 'react-router-dom';
import './index.scss'
import React from 'react';
import { Col, Layout, Image, Row } from 'antd';
import car01 from '../../assets/images/car01.webp';
import car02 from '../../assets/images/car02.webp';
import car03 from '../../assets/images/car03.webp';
import car04 from '../../assets/images/car04.webp';
const { Header, Content, Footer } = Layout;
class Car extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Car Elevator',
      header_title: 'Car elevator',
      header_content: 'FUJI car elevator / car lift adopts world leading vehicle lifting technology to improve elevator configuration and quality, has realized the vehicle up and down freely, which will bring much more convenience to you.',
      
      top1_titel: '',
      top1_tabs: [
        {
          title: '(1) Dual Cop：',
          content: 'The driver can operate the elevator freely, no need to step out of car.'
        },
        {
          title: '(2) Special circuit control and display system:',
          content: ''
        },
        {
          title: '(3) Safety guide device:',
          content: ' It can guarantee the safety of the elevator and vehicle perfectly.'
        },
        {
          title: '(4) Opposite entrance design:',
          content: 'It helps the vehicle go in and out of elevator from both sides freely and safely.'
        },
        {
          title: '(5) Remote control is available for option:',
          content: 'It brings wireless remote control technology into car elevator. The driver can operate the elevator freely in car, no need to go out of car, also no need to press elevator COP button.'
        },
      ],
      top2_titel: 'Construction Parameters'
     
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { code } = this.state;
    console.log(this.state);
    return (
      <Layout style={{ background: '#FFFFFF' }}>

        <Content className="site-layout" style={{ padding: '1rem' }}>
          <div>
            <h2>{this.state.header_title}</h2>
            <p>{this.state.header_content}</p>
            <div style={{width:'70%',padding: '1rem 0 0 0'}} ><img src={car01} /></div>
            

          </div>

          <div>
            <h3>{this.state.top1_titel}</h3>
            {
              this.state.top1_tabs.map((item, indexs) => {
                return (<div key={indexs}>
                  <h4 className='nh4'>{item.title}</h4>
                  <p>{item.content}</p>

                </div>)
              })
            }
            <p>{this.state.top1_points}</p>
          </div>
          <div><img src={car02}/></div>
          <div>
            <h3>{this.state.top2_titel}</h3>
            <div><img src={car03}/></div>
            <hr/>
            <div><img src={car04}/></div>
          </div>
          
        </Content>
      </Layout>
    )
  }
}


export default Car;
