
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Carousel, Layout, Button, Table } from 'antd';
// const BASE_URL = 'http://192.168.0.105:8000';
const BASE_URL = 'https://333.shulan-tech.com';
// const next_url = 'http://192.168.0.111:3000'
const next_url = 'https://www.fujielevatorcn.com'
const Join = () => {
  const [title, setTitle] = useState('Contact Us');
  const [routeitems, setRouteitems] = useState([]);
  const [keyRout, setKeyRout] = useState('');
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.post(`${BASE_URL}/applets/elevatorcertificate/list`)
      .then(response => {
        setData(response.data.page.list);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const handleRowClick = (record) => {
    console.log(record,'jjj');
    const link = `/hold/${record.id}`;
    navigate(link);
  };
    const columns = [
      {
        title: '项目名称',
        dataIndex: 'entryName',
        key: 'entryName',
      },
      {
        title: '产品名称',
        dataIndex: 'productName',
        key: 'productName',
      },
      {
        title: '产品型号',
        dataIndex: 'model',
        key: 'model',
      },
      {
        title: '出厂编号',
        dataIndex: 'factoryNumber',
        key: 'factoryNumber',
      },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        render: (text, record) => (
          <Button type="primary" onClick={()=>handleRowClick(record)} >
            查看
          </Button>
        ),
      },
    ];
    return (
      <Layout>
        {/* ... 其他内容保持不变 */}
        <div style={{margin:'5px'}} >
          <h2>合格证记录</h2>
        <Table dataSource={data} bordered={true} columns={columns} rowKey="equipmentCode" />
        </div>
      </Layout>
    );
  };
  
  export default Join;
